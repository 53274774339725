const EXERCISES_DOWNLOADED = 'exercises_downloaded';
const EXERCISE_DOWNLOADED = 'exercise_downloaded';
const EXERCISE_PENDING = 'exercise_pending';
const EXERCISE_SENDING = 'exercise_sending';
const EXERCISE_FAILED = 'exercise_failed';
const EXERCISE_REMOVED = 'exercise_removed';

export {
  EXERCISE_DOWNLOADED,
  EXERCISE_FAILED,
  EXERCISE_PENDING,
  EXERCISE_REMOVED,
  EXERCISE_SENDING,
  EXERCISES_DOWNLOADED,
};

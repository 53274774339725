import axios from 'axios';
import API_ROOT from '../constants/api';
import { getHeaders } from '../services/ApiServices';

const ROUTE = '/admin/exercises';

export const apiGetExercises = (token) => axios
  .get(`${API_ROOT}${ROUTE}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiPostExercise = (token, data) => axios
  .post(
    `${API_ROOT}${ROUTE}`,
    data,
    {
      headers: {
        ...getHeaders(token),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )
  .then((response) => response.data);

export const apiPutExercise = (token, id, data) => axios
  .post(
    `${API_ROOT}${ROUTE}/${id}`,
    data,
    {
      headers: {
        ...getHeaders(token),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )
  .then((response) => response.data);

export const apiDeleteExercise = (token, id) => axios
  .delete(`${API_ROOT}${ROUTE}/${id}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { artheBlueHover, artheBlue } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    container: {
      maxWidth: '129px',
      minWidth: 'unset',
      backgroundColor: artheBlue,
      color: '#FFFFFF',
      borderRadius: '13px',
      boxShadow: '0px 5px 8px 0px rgba(40, 119, 135, 0.08)',
      '&:hover': {
        backgroundColor: artheBlueHover,
      },
    },
  }
));

const SaveButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      type="button"
      className={classes.container}
      onClick={onClick}
    >
      Sauvegarder
    </Button>
  );
};

export default SaveButton;

SaveButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
};

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import {
  EXERCISE_ENCOURAGEMENT_MESSAGE_ADD,
  EXERCISE_ENCOURAGEMENT_MESSAGE_EDIT,
} from '../../../constants/routes';
import ValidationDialog from '../../../components/ValidationDialog/ValidationDialog';
import PageCircularProgress from '../../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../../components/PageTitle/PageTitle';
import {
  deleteExerciseEncouragementMessage,
  getExerciseEncouragementMessages,
} from '../../../store/exerciseEncouragementMessage/action';
import { useUserAuth } from '../../../contexts/UserAuthContext.js';

export default function ExerciseEncouragementMessagesPage() {
  const {
    pending,
    content,
    error,
    sending,
  } = useSelector((state) => state.exerciseEncouragementMessage);
  const dispatch = useDispatch();
  const { token } = useUserAuth();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [
    exerciseEncouragementMessageToDelete,
    setExerciseEncouragementMessageToDelete,
  ] = useState(null);

  if (!content && !pending && !error && token !== null) {
    dispatch(getExerciseEncouragementMessages(token));
  }

  const handleAdd = () => {
    navigate(EXERCISE_ENCOURAGEMENT_MESSAGE_ADD);
  };

  const handleEdit = (exerciseEncouragementMessagesPage) => {
    navigate(
      EXERCISE_ENCOURAGEMENT_MESSAGE_EDIT
        .replace(':exerciseEncouragementMessageId', exerciseEncouragementMessagesPage.id),
    );
  };

  const handleToggleDialog = (exerciseEncouragementMessage) => {
    setExerciseEncouragementMessageToDelete(exerciseEncouragementMessage);
    setOpenDialog(!openDialog);
  };

  const handleDelete = (exerciseEncouragementMessage) => {
    dispatch(deleteExerciseEncouragementMessage(token, exerciseEncouragementMessage))
      .then(handleToggleDialog);
  };

  return (
    <Container maxWidth={false}>
      <ValidationDialog
        handleClose={handleToggleDialog}
        openDialog={openDialog}
        handleAction={() => handleDelete(exerciseEncouragementMessageToDelete)}
        title="Êtes-vous sûr de vouloir supprimer cet encouragement ?"
        text="Vous êtes sur le point de supprimer un encouragement. Attention, cette action est irréversible."
        sending={sending}
      />
      <PageTitle
        title="Encouragements pendant les exercices"
        onClick={handleAdd}
        actionTitle="Ajouter un encouragement"
      />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((exerciseEncouragementMessage) => (
                  <TableRow key={exerciseEncouragementMessage.id}>
                    <TableCell>
                      {exerciseEncouragementMessage.title}
                    </TableCell>
                    <TableCell>
                      {exerciseEncouragementMessage.message}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        id="edit"
                        aria-label="update"
                        onClick={() => handleEdit(exerciseEncouragementMessage)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        id="delete"
                        aria-label="update"
                        onClick={() => handleToggleDialog(exerciseEncouragementMessage)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Container>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import AdviceForm from '../../components/forms/AdviceForm';
import { getAdvices } from '../../store/advice/action';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

export default function AddAdvicePage() {
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.advice);
  const dispatch = useDispatch();
  const { token } = useUserAuth();

  if (!content && !pending && !error && token !== null) {
    dispatch(getAdvices(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Ajouter un conseil" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <AdviceForm />
        )}
    </Container>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Dropzone from 'react-dropzone';
import Image from '@mui/icons-material/Image';
import TheatersIcon from '@mui/icons-material/Theaters';
import CardMedia from '@mui/material/CardMedia';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CardActions from '@mui/material/CardActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Card, CardContent, FormHelperText, Paper,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { postExercise, putExercise } from '../../store/exercise/action';
import { EXERCISES } from '../../constants/routes';
import { minInputWidth } from '../../constants/stylesDefault';
import { artheNuance100, artheDarkBlue, artheNuance300 } from '../../constants/colors';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

const useStyles = makeStyles((theme) => (
  {
    form: {
      width: '100%',
    },
    imgPic: {
      margin: 'auto',
      marginTop: '8px',
    },
    cardContent: {
      textAlign: 'center',
    },
    dropzoneGridItem: {
      minWidth: minInputWidth,
      width: '40vw',
      maxWidth: '100%',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '16px',
      padding: '30px 40px 40px 40px',
      marginTop: '21px',
    },
  }
));

export default function ExerciseForm({
  exercise,
  necessaryEquipments,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sending } = useSelector((state) => state.exercise);
  const { token } = useUserAuth();

  const { notifyApiError } = useContext(NotificationContext);

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [level, setLevel] = useState(1);
  const [levelError, setLevelError] = useState('');
  const [type, setType] = useState('');
  const [typeError, setTypeError] = useState('');
  const [nbRepetition, setNbRepetition] = useState(1);
  const [nbRepetitionError, setNbRepetitionError] = useState('');
  const [timer, setTimer] = useState(null);
  const [timerError, setTimerError] = useState('');
  const [timerLabel, setTimerLabel] = useState('');
  const [timerLabelError, setTimerLabelError] = useState('');
  const [video, setVideo] = useState(null);
  const [videoPath, setVideoPath] = useState('');
  const [deleteVideo, setDeleteVideo] = useState(false);
  const [picture, setPicture] = useState(null);
  const [picturePath, setPicturePath] = useState('');
  const [deletePicture, setDeletePicture] = useState(false);
  const [whatNotToDo, setWhatNotToDo] = useState(null);
  const [whatNotToDoPath, setWhatNotToDoPath] = useState('');
  const [deleteWhatNotToDo, setDeleteWhatNotToDo] = useState(false);
  const [necessaryEquipmentsId, setNecessaryEquipmentsId] = useState([]);

  useEffect(() => {
    if (exercise) {
      setId(exercise.id);
      setTitle(exercise.title);
      setDescription(exercise.description);
      setLevel(exercise.level);
      setType(exercise.type);
      setNbRepetition(exercise.nb_repetition);
      setVideoPath(exercise.video_path);
      setPicturePath(exercise.image_path);
      setWhatNotToDoPath(exercise.what_not_to_do_image_path);
      setTimerLabel(exercise.timer_label);
      setTimer(exercise.timer);
    }
  }, [exercise]);

  useEffect(() => {
    const mandatoryEquipments = [...necessaryEquipmentsId];
    necessaryEquipments.forEach((ne) => {
      if (ne.is_mandatory || (
        exercise && exercise.necessary_equipments_id.includes(ne.id)
      )) {
        mandatoryEquipments.push(ne.id);
      }
    });
    setNecessaryEquipmentsId(mandatoryEquipments);
  }, [necessaryEquipments, exercise]);

  const isEditing = id !== '';

  const supportedDropFiles = [
    'image/bmp',
    'image/x-ms-bmp',
    'image/png',
    'image/x-icon',
    'image/vnd.microsoft.icon',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
  ].toString();

  const supportedDropVideoFiles = [
    'video/*',
  ].toString();

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let errorCount = 0;
    if (title.trim() === '') {
      errorCount++;
      setTitleError('Champ obligatoire');
    }
    if (description.trim() === '') {
      errorCount++;
      setDescriptionError('Champ obligatoire');
    }
    if (Number.isNaN(level) || level === '') {
      errorCount++;
      setLevelError('Champ obligatoire');
    } else if (level < 1 || level > 3) {
      errorCount++;
      setLevelError('Le niveau doit valoir 1, 2 ou 3');
    }
    if (Number.isNaN(level) || level === '') {
      errorCount++;
      setNbRepetitionError('Champ obligatoire');
    } else if (nbRepetition < 1) {
      errorCount++;
      setNbRepetitionError('Le nombre de répétition doit être supérieur à 0');
    }
    const nullTimer = timer === null || timer === undefined || timer === '';
    const nullTimerLabel = timerLabel === null || timerLabel === undefined || timerLabel === '';
    if (
      (
        nullTimer && !nullTimerLabel
      )
      || (
        !nullTimer && nullTimerLabel
      ) // <=> xor
    ) {
      const timerErrorMsg = 'Le timer et son label doivent tous les deux être renseigner pour ajouter un timer';
      if (nullTimer) {
        errorCount++;
        setTimerError(timerErrorMsg);
      } else if (nullTimerLabel) {
        errorCount++;
        setTimerLabelError(timerErrorMsg);
      }
    } else {
      setTimerError('');
      setTimerLabelError('');
    }
    if (timer < 0) {
      errorCount++;
      setTimerError('Le timer doit être supérieur à 0');
    }
    if (errorCount === 0 && token !== null) {
      const data = {
        id,
        title,
        description,
        level,
        type,
        nb_repetition: nbRepetition,
        video,
        picture,
        timer,
        timer_label: timerLabel,
        video_path: videoPath,
        delete_video: deleteVideo,
        picture_path: picturePath,
        delete_picture: deletePicture,
        what_not_to_do: whatNotToDo,
        what_not_to_do_path: whatNotToDoPath,
        delete_what_not_to_do: deleteWhatNotToDo,
        necessary_equipments_id: necessaryEquipmentsId,
      };
      const formData = new FormData();
      formData.append('content', JSON.stringify(data));
      if (picture) {
        formData.append('image', picture);
      }
      if (video) {
        formData.append('video', video);
      }
      if (whatNotToDo) {
        formData.append('what_not_to_do', whatNotToDo);
      }
      if (isEditing) {
        dispatch(putExercise(token, exercise.id, formData))
          .then(() => navigate(EXERCISES))
          .catch((apiError) => notifyApiError(apiError));
      } else {
        dispatch(postExercise(token, formData))
          .then(() => navigate(EXERCISES))
          .catch((apiError) => notifyApiError(apiError));
      }
    } else {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }
  };

  const handleMediaDrop = (accepted, rejected) => {
    if (!rejected.length) {
      setPicturePath(URL.createObjectURL(accepted[0]));
      setPicture(accepted[0]);
    }
  };

  const handleDeletePicture = () => {
    setPicturePath('');
    setPicture(null);
    setDeletePicture(true);
  };

  const handleVideoDrop = (accepted, rejected) => {
    if (!rejected.length) {
      setVideoPath(URL.createObjectURL(accepted[0]));
      setVideo(accepted[0]);
    }
  };

  const handleDeleteVideo = () => {
    setVideoPath('');
    setVideo(null);
    setDeleteVideo(true);
  };

  const handleWhatNotToDoDrop = (accepted, rejected) => {
    if (!rejected.length) {
      setWhatNotToDoPath(URL.createObjectURL(accepted[0]));
      setWhatNotToDo(accepted[0]);
    }
  };

  const handleDeleteWhatNotToDo = () => {
    setWhatNotToDoPath('');
    setWhatNotToDo(null);
    setDeleteWhatNotToDo(true);
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            id="title"
            name="title"
            type="string"
            label="Titre"
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            value={title}
            multiline
            error={titleError !== ''}
            helperText={titleError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <FormControl variant="standard" error={typeError !== ''}>
            <InputLabel id="labelType" error={typeError !== ''}>
              Type
            </InputLabel>
            <Select
              labelId="labelType"
              id="type"
              name="type"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                setTypeError('');
              }}
              error={typeError !== ''}
            >
              <MenuItem value="ARTI">Echauffement articulaire</MenuItem>
              <MenuItem value="CARDIO">Echauffement cardiovasculaire</MenuItem>
              <MenuItem value="MUSCU">Renforcement musculaire</MenuItem>
              <MenuItem value="PROPRIO">Proprioception</MenuItem>
              <MenuItem value="STRETCH">Etirement</MenuItem>
            </Select>
            <FormHelperText error={typeError !== ''}>
              {typeError}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            id="description"
            name="description"
            label="Description"
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError('');
            }}
            value={description}
            multiline
            fullWidth
            error={descriptionError !== ''}
            helperText={descriptionError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            id="level"
            name="level"
            type="number"
            label="Niveau"
            onChange={(e) => {
              setLevel(parseInt(e.target.value, 10));
              setLevelError('');
            }}
            value={level}
            error={levelError !== ''}
            helperText={levelError}
            inputProps={{
              min: '1',
              max: '3',
              step: '1',
            }}
            variant="standard"
          />
        </Grid>

        <Grid item>
          <TextField
            id="nbRepetition"
            name="nbRepetition"
            type="number"
            label="Nombre de répétition"
            onChange={(e) => {
              setNbRepetition(parseInt(e.target.value, 10));
              setNbRepetitionError('');
            }}
            value={nbRepetition}
            error={nbRepetitionError !== ''}
            helperText={nbRepetitionError}
            inputProps={{
              min: '1',
              step: '1',
            }}
            variant="standard"
          />
        </Grid>

        <Grid item>
          <TextField
            id="timerLabel"
            name="timerLabel"
            type="string"
            label="Label du timer"
            onChange={(e) => {
              setTimerLabel(e.target.value);
              setTimerLabelError('');
            }}
            value={timerLabel}
            multiline
            error={timerLabelError !== ''}
            helperText={timerLabelError}
            variant="standard"
          />
        </Grid>

        <Grid item>
          <TextField
            id="timer"
            name="timer"
            type="number"
            label="Timer en secondes"
            onChange={(e) => {
              const newVal = parseInt(e.target.value, 10);
              if (!Number.isNaN(newVal)) {
                setTimer(newVal);
              } else {
                setTimer(null);
              }
              setTimerError('');
            }}
            inputProps={{
              min: '0',
              step: '1',
            }}
            value={timer}
            error={timerError !== ''}
            helperText={timerError}
            variant="standard"
          />
        </Grid>
        <Grid item className={classes.dropzoneGridItem}>
          <Typography variant="body1">
            {picturePath
              ? 'Image d\'exercice actuelle'
              : 'Glisser votre image d\'exercice dans cette zone'}
          </Typography>
          <Dropzone
            onDrop={handleMediaDrop}
            accept={supportedDropFiles}
            disabled={picturePath !== null && picturePath !== ''}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <section>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <div {...getRootProps()}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input {...getInputProps()} />
                  <Card variant="outlined">
                    <CardContent className={classes.cardContent}>
                      {picturePath
                        ? (
                          <CardMedia
                            component="img"
                            image={picturePath}
                            title="Image"
                            className={classes.imgPic}
                          />
                        )
                        : (
                          <Image className={classes.imgPic} />
                        )}
                    </CardContent>
                    {picturePath
                      && (
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={handleDeletePicture}
                            variant="outlined"
                          >
                            {'Supprimer l\'image'}
                          </Button>
                        </CardActions>
                      )}
                  </Card>
                </div>
              </section>
            )}
          </Dropzone>

        </Grid>
        <Grid item className={classes.dropzoneGridItem}>
          <Typography variant="body1">
            {videoPath
              ? 'Vidéo d\'exercice actuelle'
              : 'Glissez votre vidéo d\'exercice dans cette zone.'}
          </Typography>
          <Dropzone
            onDrop={handleVideoDrop}
            accept={supportedDropVideoFiles}
            disabled={videoPath !== null && videoPath !== ''}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <section>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <div {...getRootProps()}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input {...getInputProps()} />
                  <Card variant="outlined">
                    <CardContent className={classes.cardContent}>
                      {videoPath
                        ? (
                          <CardMedia
                            component="video"
                            src={videoPath}
                            title="Vidéo"
                            className={classes.imgPic}
                          />
                        )
                        : (
                          <TheatersIcon className={classes.imgPic} />
                        )}
                    </CardContent>
                    {videoPath
                      && (
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={handleDeleteVideo}
                            variant="outlined"
                          >
                            Supprimer la vidéo
                          </Button>
                        </CardActions>
                      )}
                  </Card>
                </div>
              </section>
            )}
          </Dropzone>

        </Grid>
        <Grid item className={classes.dropzoneGridItem}>
          <Typography variant="body1">
            {picturePath
              ? 'Image "Geste(s) à ne pas faire" actuelle'
              : 'Glisser votre image "Geste(s) à ne pas faire" dans cette zone'}
          </Typography>
          <Dropzone
            onDrop={handleWhatNotToDoDrop}
            accept={supportedDropFiles}
            disabled={whatNotToDoPath !== null && whatNotToDoPath !== ''}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <section>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <div {...getRootProps()}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <input {...getInputProps()} />
                  <Card variant="outlined">
                    <CardContent className={classes.cardContent}>
                      {whatNotToDoPath
                        ? (
                          <CardMedia
                            component="img"
                            image={whatNotToDoPath}
                            title="Image"
                            className={classes.imgPic}
                          />
                        )
                        : (
                          <Image className={classes.imgPic} />
                        )}
                    </CardContent>
                    {whatNotToDoPath
                      && (
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={handleDeleteWhatNotToDo}
                            variant="outlined"
                          >
                            {'Supprimer l\'image'}
                          </Button>
                        </CardActions>
                      )}
                  </Card>
                </div>
              </section>
            )}
          </Dropzone>

        </Grid>
        {necessaryEquipments && necessaryEquipments.length > 0 && (
          <Grid item className={classes.dropzoneGridItem}>
            <Paper className={classes.paper} elevation={0}>
              <Typography
                variant="subtitle1"
                style={{ marginBottom: '30px' }}
              >
                Matériel global
              </Typography>
              <Grid
                container
                direction="column"
                wrap="wrap"
                style={{ padding: '10px 30px 30px' }}
                spacing={4}
              >
                {
                  necessaryEquipments.map((ne) => (
                    <FormGroup key={ne.id}>
                      <FormControlLabel
                        label={ne.name}
                        disableTypography
                        style={{
                          color: necessaryEquipmentsId.includes(ne.id)
                            ? artheDarkBlue
                            : artheNuance300,
                          fontWeight: necessaryEquipmentsId.includes(ne.id)
                            ? '700'
                            : 'normal',
                        }}
                        control={(
                          <Checkbox
                            color="primary"
                            checked={necessaryEquipmentsId.includes(ne.id)}
                            onChange={() => {
                              if (necessaryEquipmentsId.includes(ne.id)) {
                                setNecessaryEquipmentsId(
                                  necessaryEquipmentsId.filter(
                                    (actualNeId) => actualNeId !== ne.id,
                                  ),
                                );
                              } else {
                                setNecessaryEquipmentsId([...necessaryEquipmentsId, ne.id]);
                              }
                            }}
                            disabled={ne.is_mandatory}

                          />
                        )}
                      />
                      <Divider style={{ backgroundColor: artheNuance100 }} />
                    </FormGroup>
                  ))
                }
              </Grid>
            </Paper>
          </Grid>
        )}
        <Grid item style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={sending}
          >
            {isEditing
              ? 'Editer'
              : 'Valider'}
            {sending && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

ExerciseForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  exercise: PropTypes.object,
  necessaryEquipments: PropTypes.array,
};

ExerciseForm.defaultProps = {
  exercise: null,
  necessaryEquipments: null,
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { artheWhite } from '../../constants/colors';
import ArrowRight from '../icon/ArrowRight';

const useStyles = makeStyles({
  fab: {
    position: 'absolute',
    bottom: '-4px',
    right: '50px',
    borderRadius: '8px',
    color: artheWhite,
    width: '45px',
    height: '45px',
  },
});

const EncouragementCard = (props) => {
  const {
    icon,
    title,
    description,
    link,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(link);
  };
  return (
    <Grid
      item
      xs={12}
      md={6}
      style={{
        position: 'relative',
        paddingBottom: '22px',
        marginTop: '10px',
      }}
    >
      <Paper
        style={{
          padding: '25px',
          borderRadius: '13px',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            {icon}
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" style={{ textAlign: 'center' }}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Fab
        color="primary"
        onClick={handleClick}
        className={classes.fab}
      >
        <ArrowRight />
      </Fab>
    </Grid>
  );
};

EncouragementCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default EncouragementCard;

const ADVICES_DOWNLOADED = 'advices_downloaded';
const ADVICE_DOWNLOADED = 'advice_downloaded';
const ADVICE_PENDING = 'advice_pending';
const ADVICE_SENDING = 'advice_sending';
const ADVICE_FAILED = 'advice_failed';
const ADVICE_REMOVED = 'advice_removed';
const ADVICE_RESET = 'advice_reset';

export {
  ADVICE_DOWNLOADED,
  ADVICE_FAILED,
  ADVICE_PENDING,
  ADVICE_REMOVED,
  ADVICE_RESET,
  ADVICE_SENDING,
  ADVICES_DOWNLOADED,
};

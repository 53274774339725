const PROGRAMS_DOWNLOADED = 'programs_downloaded';
const PROGRAMS_UPDATED = 'programs_updated';
const PROGRAM_DOWNLOADED = 'program_downloaded';
const PROGRAM_PENDING = 'program_pending';
const PROGRAM_SENDING = 'program_sending';
const PROGRAM_FAILED = 'program_failed';
const PROGRAM_REMOVED = 'program_removed';
const PROGRAM_CHANGE_ORDER = 'program_change_order';

export {
  PROGRAM_DOWNLOADED,
  PROGRAMS_UPDATED,
  PROGRAM_FAILED,
  PROGRAM_PENDING,
  PROGRAM_REMOVED,
  PROGRAM_SENDING,
  PROGRAMS_DOWNLOADED,
  PROGRAM_CHANGE_ORDER,
};

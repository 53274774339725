import React from 'react';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import PageCircularProgress from '../../../components/PageCircularProgress/PageCircularProgress';
import {
  getCongratulationsMessages,
} from '../../../store/congratulationsMessage/action';
import PageTitle from '../../../components/PageTitle/PageTitle';
import CongratulationsMessageForm from '../../../components/forms/CongratulationsMessageForm';
import { useUserAuth } from '../../../contexts/UserAuthContext.js';

export default function AddCongratulationsMessagePage() {
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.congratulationsMessage);
  const dispatch = useDispatch();
  const { token } = useUserAuth();

  if (!content && !pending && !error) {
    dispatch(getCongratulationsMessages(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Ajouter un encouragement de fin de programme" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <CongratulationsMessageForm />
        )}
    </Container>
  );
}

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Container, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { deleteAdvice, getAdvices } from '../../store/advice/action';
import { ADVICE_ADD, ADVICE_EDIT } from '../../constants/routes';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import ValidationDialog from '../../components/ValidationDialog/ValidationDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import { getAdviceThemeLabel } from '../../constants/adviceTheme';

export default function AdvicePage() {
  const {
    pending,
    sending,
    content,
    error,
  } = useSelector((state) => state.advice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [adviceToDelete, setAdviceToDelete] = useState(null);
  const { token } = useUserAuth();

  if (!content && !pending && !error && token !== null) {
    dispatch(getAdvices(token));
  }

  const handleAdd = () => {
    navigate(ADVICE_ADD);
  };

  const handleEdit = (advice) => {
    navigate(ADVICE_EDIT.replace(':adviceId', advice.id));
  };

  const handleToggleDialog = (advice) => {
    setAdviceToDelete(advice);
    setOpenDialog(!openDialog);
  };

  const handleDelete = (advice) => {
    dispatch(deleteAdvice(token, advice))
      .then(handleToggleDialog);
  };

  return (
    <Container maxWidth={false}>
      <ValidationDialog
        handleClose={handleToggleDialog}
        openDialog={openDialog}
        handleAction={() => handleDelete(adviceToDelete)}
        title="Êtes-vous sûr de vouloir supprimer ce conseil ?"
        text="Vous êtes sur le point de supprimer un conseil. Attention, cette action est irréversible."
        sending={sending}
      />
      <PageTitle
        title="Conseils"
        onClick={handleAdd}
        actionTitle="Ajouter un conseil"
      />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Theme</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((advice) => (
                  <TableRow key={advice.id}>
                    <TableCell>
                      {advice.description}
                    </TableCell>
                    <TableCell>
                      {getAdviceThemeLabel(advice.theme)}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        id="edit"
                        aria-label="update"
                        onClick={() => handleEdit(advice)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        id="delete"
                        aria-label="update"
                        onClick={() => handleToggleDialog(advice)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Container>
  );
}

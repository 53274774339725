const NECESSARY_EQUIPMENTS_DOWNLOADED = 'necessary_equipments_downloaded';
const NECESSARY_EQUIPMENT_DOWNLOADED = 'necessary_equipment_downloaded';
const NECESSARY_EQUIPMENT_PENDING = 'necessary_equipment_pending';
const NECESSARY_EQUIPMENT_SENDING = 'necessary_equipment_sending';
const NECESSARY_EQUIPMENT_FAILED = 'necessary_equipment_failed';
const NECESSARY_EQUIPMENT_REMOVED = 'necessary_equipment_removed';

export {
  NECESSARY_EQUIPMENTS_DOWNLOADED,
  NECESSARY_EQUIPMENT_DOWNLOADED,
  NECESSARY_EQUIPMENT_PENDING,
  NECESSARY_EQUIPMENT_SENDING,
  NECESSARY_EQUIPMENT_FAILED,
  NECESSARY_EQUIPMENT_REMOVED,
};

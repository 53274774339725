import {
  NECESSARY_EQUIPMENTS_DOWNLOADED,
  NECESSARY_EQUIPMENT_PENDING,
  NECESSARY_EQUIPMENT_FAILED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case NECESSARY_EQUIPMENTS_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case NECESSARY_EQUIPMENT_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case NECESSARY_EQUIPMENT_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    default:
      break;
  }
  return state;
}

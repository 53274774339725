import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';
import CheckMarkIcon from '../icon/CheckMarkIcon';
import { getNecessaryEquipments } from '../../store/necessaryEquipment/action';
import { artheNuance100, artheDarkBlue } from '../../constants/colors';
import NecessaryEquipmentDialog from '../../containers/NecessaryEquipment/NecessaryEquipmentDialog';
import PageCircularProgress from '../PageCircularProgress/PageCircularProgress';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

const useStyles = makeStyles((theme) => (
  {
    root: {
      minWidth: '254px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '16px',
      padding: '21px 25px 15px 25px',
      marginTop: '21px',
    },
    list: {
      backgroundColor: artheNuance100,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }
));

const NecessaryEquipment = () => {
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.necessaryEquipment);
  const dispatch = useDispatch();
  const { token } = useUserAuth();

  if (content === null && !pending && error === null && token !== null) {
    dispatch(getNecessaryEquipments(token));
  }
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      {pending && <PageCircularProgress />}
      {(
        content !== null && !pending
      )
        && (
          <List className={classes.root}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">
                Matériel global
              </Typography>
              <IconButton
                id="edit"
                aria-label="update"
                color="default"
                onClick={handleClickOpen}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            {content.map((n) => (
              <div key={n.id}>
                <Divider className={classes.list} />
                <ListItem>
                  <ListItemIcon>
                    <CheckMarkIcon />
                  </ListItemIcon>
                  <ListItemText primary={n.name} />
                </ListItem>
              </div>
            ))}
            <Dialog
              open={open}
              PaperProps={{
                style: { borderRadius: '16px' },
              }}
            >
              <DialogTitle
                variant="h6"
                style={{ color: artheDarkBlue }}
              >
                Gérer le matériel global
              </DialogTitle>
              <IconButton className={classes.closeButton} onClick={() => setOpen(!open)}>
                <CloseIcon />
              </IconButton>
              <NecessaryEquipmentDialog onClose={handleClose} content={content} />
            </Dialog>
          </List>
        )}
    </>
  );
};

export default NecessaryEquipment;

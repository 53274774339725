import {
  NECESSARY_EQUIPMENTS_DOWNLOADED,
  NECESSARY_EQUIPMENT_DOWNLOADED,
  NECESSARY_EQUIPMENT_PENDING,
  NECESSARY_EQUIPMENT_SENDING,
  NECESSARY_EQUIPMENT_FAILED,
  NECESSARY_EQUIPMENT_REMOVED,
} from './types';
import {
  apiDeleteNecessaryEquipment, apiGetNecessaryEquipments, apiPostNecessaryEquipment,
  apiPutNecessaryEquipment,
} from '../../api/NecessaryEquipmentApi';

export const necessaryEquipmentsDownloadedAction = (necessaryEquipment) => (
  {
    type: NECESSARY_EQUIPMENTS_DOWNLOADED,
    payload: necessaryEquipment,
  }
);
export const necessaryEquipmentDownloadedAction = (necessaryEquipment) => (
  {
    type: NECESSARY_EQUIPMENT_DOWNLOADED,
    payload: necessaryEquipment,
  }
);
export const necessaryEquipmentPendingAction = () => (
  {
    type: NECESSARY_EQUIPMENT_PENDING,
  }
);
export const necessaryEquipmentSendingAction = () => (
  {
    type: NECESSARY_EQUIPMENT_SENDING,
  }
);
export const necessaryEquipmentRemovedAction = (necessaryEquipment) => (
  {
    type: NECESSARY_EQUIPMENT_REMOVED,
    payload: necessaryEquipment,
  }
);
export const necessaryEquipmentFailedAction = (error) => (
  {
    type: NECESSARY_EQUIPMENT_FAILED,
    payload: error,
  }
);

export function getNecessaryEquipments(token) {
  return (dispatch) => {
    dispatch(necessaryEquipmentPendingAction());
    return apiGetNecessaryEquipments(token)
      .then((result) => {
        dispatch(necessaryEquipmentsDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(necessaryEquipmentFailedAction(error));
        throw error;
      });
  };
}

export function postNecessaryEquipment(token, data) {
  return (dispatch) => {
    dispatch(necessaryEquipmentSendingAction());
    return apiPostNecessaryEquipment(token, data)
      .then((result) => {
        dispatch(necessaryEquipmentsDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(necessaryEquipmentFailedAction(error));
        throw error;
      });
  };
}

export function putNecessaryEquipment(token, id, data) {
  return (dispatch) => {
    dispatch(necessaryEquipmentSendingAction());
    return apiPutNecessaryEquipment(token, id, data)
      .then((result) => {
        dispatch(necessaryEquipmentDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(necessaryEquipmentFailedAction(error));
        throw error;
      });
  };
}

export function deleteNecessaryEquipment(token, program) {
  return (dispatch) => {
    dispatch(necessaryEquipmentSendingAction());
    return apiDeleteNecessaryEquipment(token, program.id)
      .then(() => {
        dispatch(necessaryEquipmentRemovedAction(program));
        return program;
      })
      .catch((error) => {
        dispatch(necessaryEquipmentFailedAction(error));
        throw error;
      });
  };
}

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
  },
  paper: {
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: theme.spacing(6),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      border: 0,
      padding: 0,
      boxShadow: 'none',
    },
  },
  logo: {
    width: '100px',
    height: '100px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: '24px',
  },
}));

export default useStyles;

import {
  QUESTION_REMOVED,
  QUESTION_DOWNLOADED,
  QUESTION_FAILED,
  QUESTION_PENDING,
  QUESTIONS_DOWNLOADED,
  QUESTION_SENDING,
} from './types';
import {
  apiDeleteQuestion,
  apiGetQuestions,
  apiPostQuestion,
  apiPutQuestion,
} from '../../api/QuestionApi';

export const questionsDownloadedAction = (question) => (
  {
    type: QUESTIONS_DOWNLOADED,
    payload: question,
  }
);
export const questionDownloadedAction = (question) => (
  {
    type: QUESTION_DOWNLOADED,
    payload: question,
  }
);
export const questionPendingAction = () => (
  {
    type: QUESTION_PENDING,
  }
);
export const questionSendingAction = () => (
  {
    type: QUESTION_SENDING,
  }
);
export const questionRemovedAction = (question) => (
  {
    type: QUESTION_REMOVED,
    payload: question,
  }
);
export const questionFailedAction = (error) => (
  {
    type: QUESTION_FAILED,
    payload: error,
  }
);

export function getQuestions(token) {
  return (dispatch) => {
    dispatch(questionPendingAction());
    return apiGetQuestions(token)
      .then((result) => {
        dispatch(questionsDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(questionFailedAction(error));
        throw error;
      });
  };
}

export function postQuestion(token, data) {
  return (dispatch) => {
    dispatch(questionSendingAction());
    return apiPostQuestion(token, data)
      .then((result) => {
        dispatch(questionDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(questionFailedAction(error));
        throw error;
      });
  };
}

export function putQuestion(token, id, data) {
  return (dispatch) => {
    dispatch(questionSendingAction());
    return apiPutQuestion(token, id, data)
      .then((result) => {
        dispatch(questionDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(questionFailedAction(error));
        throw error;
      });
  };
}

export function deleteQuestion(token, question) {
  return (dispatch) => {
    dispatch(questionSendingAction());
    return apiDeleteQuestion(token, question.id)
      .then(() => {
        dispatch(questionRemovedAction(question));
        return question;
      })
      .catch((error) => {
        dispatch(questionFailedAction(error));
        throw error;
      });
  };
}

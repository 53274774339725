import {
  STATISTICS_ENCOURAGEMENT_MESSAGE_REMOVED,
  STATISTICS_ENCOURAGEMENT_MESSAGE_FAILED,
  STATISTICS_ENCOURAGEMENT_MESSAGE_PENDING,
  STATISTICS_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
  STATISTICS_ENCOURAGEMENT_MESSAGE_SENDING,
  STATISTICS_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
} from './types';
import {
  apiDeleteStatisticsEncouragementMessage,
  apiGetStatisticsEncouragementMessages,
  apiPostStatisticsEncouragementMessage,
  apiPutStatisticsEncouragementMessage,
} from '../../api/StatisticsEncouragementMessageApi';

export const statisticsEncouragementMessagesDownloadedAction = (statisticsEncouragementMessage) => (
  {
    type: STATISTICS_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
    payload: statisticsEncouragementMessage,
  }
);
export const statisticsEncouragementMessageDownloadedAction = (statisticsEncouragementMessage) => (
  {
    type: STATISTICS_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
    payload: statisticsEncouragementMessage,
  }
);
export const statisticsEncouragementMessagePendingAction = () => (
  {
    type: STATISTICS_ENCOURAGEMENT_MESSAGE_PENDING,
  }
);
export const statisticsEncouragementMessageSendingAction = () => (
  {
    type: STATISTICS_ENCOURAGEMENT_MESSAGE_SENDING,
  }
);
export const statisticsEncouragementMessageRemovedAction = (statisticsEncouragementMessage) => (
  {
    type: STATISTICS_ENCOURAGEMENT_MESSAGE_REMOVED,
    payload: statisticsEncouragementMessage,
  }
);
export const statisticsEncouragementMessageFailedAction = (error) => (
  {
    type: STATISTICS_ENCOURAGEMENT_MESSAGE_FAILED,
    payload: error,
  }
);

export function getStatisticsEncouragementMessages(token) {
  return (dispatch) => {
    dispatch(statisticsEncouragementMessagePendingAction());
    return apiGetStatisticsEncouragementMessages(token)
      .then((result) => {
        dispatch(statisticsEncouragementMessagesDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(statisticsEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

export function postStatisticsEncouragementMessage(token, data) {
  return (dispatch) => {
    dispatch(statisticsEncouragementMessageSendingAction());
    return apiPostStatisticsEncouragementMessage(token, data)
      .then((result) => {
        dispatch(statisticsEncouragementMessageDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(statisticsEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

export function putStatisticsEncouragementMessage(token, id, data) {
  return (dispatch) => {
    dispatch(statisticsEncouragementMessageSendingAction());
    return apiPutStatisticsEncouragementMessage(token, id, data)
      .then((result) => {
        dispatch(statisticsEncouragementMessageDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(statisticsEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

export function deleteStatisticsEncouragementMessage(token, statisticsEncouragementMessage) {
  return (dispatch) => {
    dispatch(statisticsEncouragementMessageSendingAction());
    return apiDeleteStatisticsEncouragementMessage(token, statisticsEncouragementMessage.id)
      .then(() => {
        dispatch(statisticsEncouragementMessageRemovedAction(statisticsEncouragementMessage));
        return statisticsEncouragementMessage;
      })
      .catch((error) => {
        dispatch(statisticsEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

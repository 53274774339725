import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const ProgramExerciseContext = createContext({});

const ProgramExerciseProvider = ({ children }) => {
  const [checkedExercisesNumber, setCheckedExercisesNumber] = useState(0);

  const changeProgramExercisesNumber = (isChecked) => {
    if (isChecked) {
      setCheckedExercisesNumber(checkedExercisesNumber + 1);
    } else {
      setCheckedExercisesNumber(checkedExercisesNumber - 1);
    }
  };

  return (
    <ProgramExerciseContext.Provider
      value={{
        checkedExercisesNumber,
        setCheckedExercisesNumber,
        changeProgramExercisesNumber,
      }}
    >
      {children}
    </ProgramExerciseContext.Provider>
  );
};

export {
  ProgramExerciseContext,
  ProgramExerciseProvider,
};

ProgramExerciseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import makeStyles from '@mui/styles/makeStyles';
import { artheNuance100, artheRed } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    root: {
      maxWidth: '679px',
      padding: '25px 30px 35px 30px',
    },
    container: {
      maxWidth: '619px',
      borderRadius: '8px',
      boxShadow: '0px 5px 8px 0px rgba(40, 119, 135, 0.08)',
      disabled: {
        backgroundColor: artheNuance100,
      },
    },
    input: {
      '& input.Mui-disabled': {
        color: artheRed,
      },
    },
  }
));

export default useStyles;

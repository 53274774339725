import axios from 'axios';
import API_ROOT from '../constants/api';
import { getHeaders } from '../services/ApiServices';

const ROUTE = '/admin/necessary-equipments';

export const apiGetNecessaryEquipments = (token) => axios
  .get(`${API_ROOT}${ROUTE}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiPostNecessaryEquipment = (token, data) => axios
  .post(`${API_ROOT}${ROUTE}`, data, {
    headers: {
      ...getHeaders(token),
    },
  })
  .then((response) => response.data);

export const apiPutNecessaryEquipment = (token, id, data) => axios
  .post(`${API_ROOT}${ROUTE}/${id}`, data, {
    headers: {
      ...getHeaders(token),
    },
  })
  .then((response) => response.data);

export const apiDeleteNecessaryEquipment = (token, id) => axios
  .delete(`${API_ROOT}${ROUTE}/${id}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { getExercises } from '../../store/exercise/action';
import ExerciseForm from '../../components/forms/ExerciseForm';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import { getNecessaryEquipments } from '../../store/necessaryEquipment/action';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

export default function AddExercisePage() {
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.exercise);
  const { token } = useUserAuth();

  const dispatch = useDispatch();
  const neState = useSelector((state) => state.necessaryEquipment);

  if (neState.content === null && !neState.pending && neState.error === null && token !== null) {
    dispatch(getNecessaryEquipments(token));
  }

  if (!content && !pending && !error && token !== null) {
    dispatch(getExercises(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Ajouter un exercice" />
      {(
        pending || content === null || neState.pending
      ) && <PageCircularProgress />}
      {content !== null && neState.content !== null
        && (
          <ExerciseForm necessaryEquipments={neState.content ?? []} />
        )}
    </Container>
  );
}

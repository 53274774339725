const EXERCISE_ENCOURAGEMENT_MESSAGES_DOWNLOADED = 'exercise_encouragement_messages_downloaded';
const EXERCISE_ENCOURAGEMENT_MESSAGE_DOWNLOADED = 'exercise_encouragement_message_downloaded';
const EXERCISE_ENCOURAGEMENT_MESSAGE_PENDING = 'exercise_encouragement_message_pending';
const EXERCISE_ENCOURAGEMENT_MESSAGE_SENDING = 'exercise_encouragement_message_sending';
const EXERCISE_ENCOURAGEMENT_MESSAGE_FAILED = 'exercise_encouragement_message_failed';
const EXERCISE_ENCOURAGEMENT_MESSAGE_REMOVED = 'exercise_encouragement_message_removed';

export {
  EXERCISE_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_FAILED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_PENDING,
  EXERCISE_ENCOURAGEMENT_MESSAGE_REMOVED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_SENDING,
  EXERCISE_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
};

import {
  ADVICE_REMOVED,
  ADVICE_DOWNLOADED,
  ADVICE_FAILED,
  ADVICE_PENDING,
  ADVICES_DOWNLOADED,
  ADVICE_SENDING,
  ADVICE_RESET,
} from './types';
import {
  apiDeleteAdvice,
  apiGetAdvices,
  apiPostAdvice,
  apiPutAdvice,
} from '../../api/AdviceApi';

export const advicesDownloadedAction = (advice) => (
  {
    type: ADVICES_DOWNLOADED,
    payload: advice,
  }
);
export const adviceDownloadedAction = (advice) => (
  {
    type: ADVICE_DOWNLOADED,
    payload: advice,
  }
);
export const advicePendingAction = () => (
  {
    type: ADVICE_PENDING,
  }
);
export const adviceSendingAction = () => (
  {
    type: ADVICE_SENDING,
  }
);
export const adviceRemovedAction = (advice) => (
  {
    type: ADVICE_REMOVED,
    payload: advice,
  }
);
export const adviceResetAction = () => (
  {
    type: ADVICE_RESET,
  }
);
export const adviceFailedAction = (error) => (
  {
    type: ADVICE_FAILED,
    payload: error,
  }
);

export function getAdvices(token) {
  return (dispatch) => {
    dispatch(advicePendingAction());
    return apiGetAdvices(token)
      .then((result) => {
        dispatch(advicesDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(adviceFailedAction(error));
        throw error;
      });
  };
}

export function postAdvice(token, data) {
  return (dispatch) => {
    dispatch(adviceSendingAction());
    return apiPostAdvice(token, data)
      .then((result) => {
        dispatch(adviceDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(adviceFailedAction(error));
        throw error;
      });
  };
}

export function putAdvice(token, id, data) {
  return (dispatch) => {
    dispatch(adviceSendingAction());
    return apiPutAdvice(token, id, data)
      .then((result) => {
        dispatch(adviceDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(adviceFailedAction(error));
        throw error;
      });
  };
}

export function deleteAdvice(token, advice) {
  return (dispatch) => {
    dispatch(adviceSendingAction());
    return apiDeleteAdvice(token, advice.id)
      .then(() => {
        dispatch(adviceRemovedAction(advice));
        return advice;
      })
      .catch((error) => {
        dispatch(adviceFailedAction(error));
        throw error;
      });
  };
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { NotificationProvider } from './contexts/NotificationContext';
import { UserAuthContextProvider } from './contexts/UserAuthContext.js';
import theme from './themes/theme';

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <BrowserRouter>
            <UserAuthContextProvider>
              <App />
            </UserAuthContextProvider>
          </BrowserRouter>
        </NotificationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

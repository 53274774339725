import thunk from 'redux-thunk';
import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';

import adviceReducer from './advice/reducers';
import statisticsEncouragementMessageReducer from './statisticsEncouragementMessage/reducers';
import exerciseEncouragementMessageReducer from './exerciseEncouragementMessage/reducers';
import congratulationsMessageReducer from './congratulationsMessage/reducers';
import exerciseReducer from './exercise/reducers';
import programReducer from './program/reducers';
import questionReducer from './question/reducers';
import necessaryEquipmentReducer from './necessaryEquipment/reducers';

const reducers = combineReducers({
  advice: adviceReducer,
  statisticsEncouragementMessage: statisticsEncouragementMessageReducer,
  exerciseEncouragementMessage: exerciseEncouragementMessageReducer,
  congratulationsMessage: congratulationsMessageReducer,
  exercise: exerciseReducer,
  program: programReducer,
  question: questionReducer,
  necessaryEquipment: necessaryEquipmentReducer,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Input, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { minInputWidth } from '../../constants/stylesDefault';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import { ADVICES } from '../../constants/routes';

export default function SignInForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const {
    token,
    logInPending,
    logIn,
  } = useUserAuth();

  useEffect(() => {
    if (token !== null && !logInPending) {
      navigate(ADVICES);
    }
  }, [token, logInPending]);

  const submit = (event) => {
    event.preventDefault();
    logIn(email, password)
      .catch((err) => {
        setAuthError('Email et/ou mot de passe incorrect(s)');
        setPassword('');
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={submit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="column"
      >
        <Grid item>
          <Typography variant="body1" color="error">
            {authError}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            style={{ width: minInputWidth }}
            label="Email"
            name="email"
            id="signin-email"
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
              setAuthError('');
            }}
            margin="normal"
            value={email}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              style={{ width: minInputWidth }}
              name="password"
              id="signin-password"
              type={showPassword
                ? 'text'
                : 'password'}
              label="Mot de passe"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setAuthError('');
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword
                      ? <Visibility />
                      : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={logInPending}
          >
            Connexion
            {logInPending && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

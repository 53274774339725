import {
  PROGRAMS_DOWNLOADED,
  PROGRAMS_UPDATED,
  PROGRAM_SENDING,
  PROGRAM_REMOVED,
  PROGRAM_PENDING,
  PROGRAM_FAILED,
  PROGRAM_DOWNLOADED,
  PROGRAM_CHANGE_ORDER,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: null,
  sending: null,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROGRAM_SENDING:
      return {
        ...state,
        sending: true,
      };
    case PROGRAMS_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case PROGRAMS_UPDATED: {
      const programsDownloaded = action.payload;
      const programList = state.content.slice();
      let programIndex = -1;
      programsDownloaded.forEach((program) => {
        programIndex = programList.findIndex((p) => p.id === program.id);
        if (programIndex === -1) {
          programList.push(program);
        } else {
          programList[programIndex] = program;
        }
      });
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: programList,
      };
    }
    case PROGRAM_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case PROGRAM_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case PROGRAM_DOWNLOADED: {
      const programDownloaded = action.payload;
      let programList = [];
      let programIndex = -1;
      if (state.content) {
        programList = state.content.slice();
        programIndex = programList.findIndex((program) => program.id === programDownloaded.id);
      }
      if (programIndex === -1) {
        programList.push(action.payload);
      } else {
        programList[programIndex] = action.payload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: programList,
      };
    }
    case PROGRAM_REMOVED: {
      const program = action.payload;
      let programList = state.content;
      if (programList !== null) {
        // eslint-disable-next-line func-names
        const isProgram = (programId) => function (element) {
          return element.id !== programId;
        };
        programList = state.content.filter(isProgram(program.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: programList,
      };
    }
    case PROGRAM_CHANGE_ORDER: {
      const programList = state.content;
      const { programId } = action.payload;
      const { newIndex } = action.payload;
      if (programList != null) {
        const program = programList.find((p) => p.id === programId);
        if (program) {
          programList.splice(newIndex, 0, programList.splice(program.sort_order, 1)[0]);
        }
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: true,
        content: programList,
      };
    }
    default:
      break;
  }
  return state;
}

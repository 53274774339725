import axios from 'axios';
import API_ROOT from '../constants/api';
import { getHeaders } from '../services/ApiServices';

const ROUTE = '/admin/advices';

export const apiGetAdvices = (token) => axios
  .get(`${API_ROOT}${ROUTE}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiPostAdvice = (token, data) => axios
  .post(`${API_ROOT}${ROUTE}`, data, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiPutAdvice = (token, id, data) => axios
  .put(`${API_ROOT}${ROUTE}/${id}`, data, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiDeleteAdvice = (token, id) => axios
  .delete(`${API_ROOT}${ROUTE}/${id}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

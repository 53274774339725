import React, { useState } from 'react';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { deleteExercise, getExercises } from '../../store/exercise/action';
import { EXERCISE_ADD, EXERCISE_EDIT } from '../../constants/routes';
import ValidationDialog from '../../components/ValidationDialog/ValidationDialog';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import NecessaryEquipment from '../../components/necessaryEquipments/NecessaryEquipment';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

const useStyles = makeStyles({
  image: {
    display: 'block',
    maxWidth: 230,
    maxHeight: 95,
    width: 'auto',
    height: 'auto',
  },
});

export default function ExercisePage() {
  const {
    pending,
    content,
    error,
    sending,
  } = useSelector((state) => state.exercise);
  const { token } = useUserAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [exerciseToDelete, setExerciseToDelete] = useState(null);

  if (!content && !pending && !error && token !== null) {
    dispatch(getExercises(token));
  }

  const handleAdd = () => {
    navigate(EXERCISE_ADD);
  };

  const handleEdit = (exercise) => {
    navigate(EXERCISE_EDIT.replace(':exerciseId', exercise.id));
  };

  const handleToggleDialog = (exercise) => {
    setExerciseToDelete(exercise);
    setOpenDialog(!openDialog);
  };

  const handleDelete = (exercise) => {
    dispatch(deleteExercise(token, exercise))
      .then(handleToggleDialog);
  };

  return (
    <Container maxWidth={false}>
      <ValidationDialog
        handleClose={handleToggleDialog}
        openDialog={openDialog}
        handleAction={() => handleDelete(exerciseToDelete)}
        title="Êtes-vous sûr de vouloir supprimer cet exercice ?"
        text="Vous êtes sur le point de supprimer un exercice. Attention, cette action est irréversible."
        sending={sending}
      />
      <PageTitle
        title="Exercices"
        onClick={handleAdd}
        actionTitle="Ajouter un exercice"
      />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      <Grid
        container
        direction="row"
        gap={4}
        justifyContent="flex-start"
        alignItems="flex-start"
        wrap="nowrap"
      >
        {content !== null
          && (
            <Grid item style={{ flexGrow: 1 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: '100px' }}>Titre</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Niveau</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Répétitions</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {content.map((exercise) => (
                      <TableRow key={exercise.id}>
                        <TableCell>
                          {exercise.title}
                        </TableCell>
                        <TableCell>
                          {exercise.description}
                        </TableCell>
                        <TableCell>
                          {exercise.level}
                        </TableCell>
                        <TableCell>
                          {exercise.type}
                        </TableCell>
                        <TableCell>
                          {exercise.nb_repetition}
                        </TableCell>
                        <TableCell>
                          <img src={exercise.image_path} alt="" className={classes.image} />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            id="edit"
                            aria-label="update"
                            onClick={() => handleEdit(exercise)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            id="delete"
                            aria-label="update"
                            onClick={() => handleToggleDialog(exercise)}
                            color="primary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        <Grid item>
          <NecessaryEquipment />
        </Grid>
      </Grid>
    </Container>
  );
}

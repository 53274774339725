export const arthePrimaryLight = '#436379';
export const artheDarkBlue = '#1A4765';
export const artheBlue = '#68D1D4';
export const artheBlueHover = '#37B0B3';
export const artheWhite = '#FFFFFF';
export const artheNuance050 = '#F0F8FA';
export const artheNuance100 = '#EBEEEF';
export const artheNuance200 = '#A5B3B6';
export const artheNuance300 = '#758793';
export const artheNuance500 = '#223845';
export const artheRed = '#F26979';
export const artheBlueShadow = '0px 5px 8px rgba(40, 119, 135, 0.08)';
export const artheButtonShadow = '0px 8px 10px rgba(104, 209, 212, 0.18)';
export const artheNavButtonHover = 'rgba(0, 0, 0, 0.12)';
export const arthePairTableRow = 'rgba(249, 249, 249, 0.94)';

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { deleteQuestion, getQuestions } from '../../store/question/action';
import { QUESTION_ADD, QUESTION_EDIT } from '../../constants/routes';
import ValidationDialog from '../../components/ValidationDialog/ValidationDialog';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

export default function QuestionPage() {
  const {
    pending,
    sending,
    content,
    error,
  } = useSelector((state) => state.question);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const { token } = useUserAuth();

  if (!content && !pending && !error && token !== null) {
    dispatch(getQuestions(token));
  }

  const handleAdd = () => {
    navigate(QUESTION_ADD);
  };

  const handleEdit = (question) => {
    navigate(QUESTION_EDIT.replace(':questionId', question.id));
  };

  const handleToggleDialog = (question) => {
    setQuestionToDelete(question);
    setOpenDialog(!openDialog);
  };

  const handleDelete = (question) => {
    dispatch(deleteQuestion(token, question))
      .then(handleToggleDialog);
  };

  return (
    <Container maxWidth={false}>
      <ValidationDialog
        handleClose={handleToggleDialog}
        openDialog={openDialog}
        handleAction={() => handleDelete(questionToDelete)}
        title="Êtes-vous sûr de vouloir supprimer cette question ?"
        text="Vous êtes sur le point de supprimer une question. Attention, cette action est irréversible."
        sending={sending}
      />
      <PageTitle
        title="Questions Vrai/Faux"
        onClick={handleAdd}
        actionTitle="Ajouter une question"
      />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Question</TableCell>
                  <TableCell>Affirmation</TableCell>
                  <TableCell>Theme</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((question) => (
                  <TableRow key={question.id}>
                    <TableCell>
                      {question.question}
                    </TableCell>
                    <TableCell>
                      {question.affirmation}
                    </TableCell>
                    <TableCell>
                      {question.theme}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      <IconButton
                        id="edit"
                        aria-label="update"
                        onClick={() => handleEdit(question)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        id="delete"
                        aria-label="update"
                        onClick={() => handleToggleDialog(question)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Container>
  );
}

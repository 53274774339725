import {
  EXERCISES_DOWNLOADED,
  EXERCISE_SENDING,
  EXERCISE_REMOVED,
  EXERCISE_PENDING,
  EXERCISE_FAILED,
  EXERCISE_DOWNLOADED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: null,
  sending: null,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXERCISE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case EXERCISES_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case EXERCISE_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case EXERCISE_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case EXERCISE_DOWNLOADED: {
      const exerciseDownloaded = action.payload;
      let exerciseList = [];
      let exerciseIndex = -1;
      if (state.content) {
        exerciseList = state.content.slice();
        exerciseIndex = exerciseList.findIndex((exercise) => exercise.id === exerciseDownloaded.id);
      }
      if (exerciseIndex === -1) {
        exerciseList.push(action.payload);
      } else {
        exerciseList[exerciseIndex] = action.payload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: exerciseList,
      };
    }
    case EXERCISE_REMOVED: {
      const exercise = action.payload;
      let exerciseList = state.content;
      if (exerciseList !== null) {
        // eslint-disable-next-line func-names
        const isExercise = (exerciseId) => function (element) {
          return element.id !== exerciseId;
        };
        exerciseList = state.content.filter(isExercise(exercise.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: exerciseList,
      };
    }
    default:
      break;
  }
  return state;
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import ExerciseEncouragementMessageForm
  from '../../../components/forms/ExerciseEncouragementMessageForm';
import {
  getExerciseEncouragementMessages,
} from '../../../store/exerciseEncouragementMessage/action';
import PageCircularProgress from '../../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../../contexts/UserAuthContext.js';

export default function EditExerciseEncouragementMessagePage() {
  const { exerciseEncouragementMessageId } = useParams();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.exerciseEncouragementMessage);
  const dispatch = useDispatch();
  const { token } = useUserAuth();

  const [exerciseEncouragementMessage, setExerciseEncouragementMessage] = useState(null);
  useEffect(() => {
    if (content !== null) {
      setExerciseEncouragementMessage(content.find(
        (message) => message.id === exerciseEncouragementMessageId,
      ));
    }
  }, [content]);

  if (!content && !pending && !error && token !== null) {
    dispatch(getExerciseEncouragementMessages(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Modifier un encouragement lors des exercices" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {exerciseEncouragementMessage !== null
        && (
          <ExerciseEncouragementMessageForm
            exerciseEncouragementMessage={exerciseEncouragementMessage}
          />
        )}
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import StatisticsEncouragementMessageForm
  from '../../../components/forms/StatisticsEncouragementMessageForm';
import {
  getStatisticsEncouragementMessages,
} from '../../../store/statisticsEncouragementMessage/action';
import PageCircularProgress from '../../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../../contexts/UserAuthContext.js';

export default function EditStatisticsEncouragementMessagePage() {
  const { encouragementId } = useParams();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.statisticsEncouragementMessage);
  const dispatch = useDispatch();
  const { token } = useUserAuth();
  const [statisticsEncouragementMessage, setStatisticsEncouragementMessage] = useState(null);
  useEffect(() => {
    if (content !== null) {
      setStatisticsEncouragementMessage(content.find(
        (message) => message.id === encouragementId,
      ));
    }
  }, [content]);

  if (!content && !pending && !error && token !== null) {
    dispatch(getStatisticsEncouragementMessages(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Modifier un encouragement de suivi" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {statisticsEncouragementMessage !== null
        && (
          <StatisticsEncouragementMessageForm
            statisticsEncouragementMessage={statisticsEncouragementMessage}
          />
        )}
    </Container>
  );
}

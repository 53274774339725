import React from 'react';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import PageTitle from '../../components/PageTitle/PageTitle';
import EncouragementCard from '../../components/Encouragement/EncouragementCard';
import {
  CONGRATULATIONS_MESSAGE,
  EXERCISE_ENCOURAGEMENT_MESSAGE,
  STATISTICS_ENCOURAGEMENT_MESSAGE,
} from '../../constants/routes';
import { artheBlue } from '../../constants/colors';
import ChartBar from '../../components/icon/ChartBar';
import Clipboard from '../../components/icon/Clipboard';
import IcDirectionsRun from '../../components/icon/IcDirectionsRun';

const EncouragementPage = () => (
  <Container maxWidth={false}>
    <PageTitle title="Encouragements" />
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={8}>
      <EncouragementCard
        icon={<IcDirectionsRun color={artheBlue} width={59} height={59} />}
        title="Encouragements pendant les exercices"
        description="Ces messages apparaîtront entre les exercices en cours d’un programme, afin d’encourager l’utilisateur."
        link={EXERCISE_ENCOURAGEMENT_MESSAGE}
      />
      <EncouragementCard
        icon={<Clipboard color={artheBlue} width={59} height={59} />}
        title="Encouragements en fin de programme"
        description="Ces messages apparaîtront à la fin du programme, sur l’écran de notation de la douleur."
        link={CONGRATULATIONS_MESSAGE}
      />
      <EncouragementCard
        icon={<ChartBar color={artheBlue} width={59} height={59} />}
        title="Encouragements de suivi"
        description="Ces messages apparaîtront au dessus du graphique hebdomadaire, mensuel ou annuel dans la rubrique “Suivi”."
        link={STATISTICS_ENCOURAGEMENT_MESSAGE}
      />
    </Grid>
  </Container>
);

export default EncouragementPage;

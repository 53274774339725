import React from 'react';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '../components/Menu/Menu';
import { useUserAuth } from '../contexts/UserAuthContext.js';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 40,
  },
  containerStyle: {
    width: '100%',
    maxWidth: '1200px',
    textAlign: 'justify',
    wordWrap: 'break-word',
  },
  withMenu: {
    marginLeft: '260px',
  },
});

/* eslint-disable max-len */
export default function CGUPage() {
  const classes = useStyles();
  const { token } = useUserAuth();
  return (
    <Container maxWidth={false} className={classes.root}>
      {token !== null
        ? <Menu />
        : ''}
      <Container
        maxWidth={false}
        className={`${classes.containerStyle} ${token ? classes.withMenu : ''}`}
      >
        <h1 id="conditions-generales-d-utilisation-de-l-application-arth-e-">CONDITIONS GENERALES D’UTILISATION DE L’APPLICATION « ARTH-e »</h1>
        <p>Version n° 3 en vigueur au 01 juin 2024.</p>
        <h2 id="1-objet-des-cgu">1. Objet des CGU</h2>
        <p>Les présentes conditions générales d’utilisation (CGU) ont pour objet de définir les modalités et conditions d’accès et d’utilisation d’une Application informatique de suivi de patients, ainsi que les droits et obligations des Utilisateurs dans ce cadre. </p>
        <h2 id="2-d-finitions">2. Définitions</h2>
        <ul>
          <li>
            <p>
              « CGU » : les présentes conditions générales d’utilisation de l’Application.
              {' '}
            </p>
          </li>
          <li>
            <p>« Informations personnelles » : l’expression est définie comme étant « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l&#39;identification des personnes physiques auxquelles elles s&#39;appliquent »(article 4 de la loi n° 78-17 du 6 janvier 1978).</p>
          </li>
          <li>
            <p>« Application » : l’application logicielle, sous sa forme web ou mobile à laquelle l’Utilisateur peut accéder après authentification forte, permettant la collecte et la gestion des Informations personnelles.</p>
          </li>
          <li>
            <p>« Réglementation applicable à la protection des données personnelles » : toute réglementation applicable à la protection des données personnelles et en particulier le Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 dit « Règlement Général sur la Protection des Données » et la Loi n°78-17 du 6 janvier 1978 modifiée dite « Loi Informatique et Libertés ».</p>
          </li>
          <li>
            <p>« Responsables de traitement » : le CHU de Clermont-Ferrand, propriétaire de l’Application et initiateur du projet ARTH-e et son sous-traitant, la société OPENIUM.</p>
          </li>
          <li>
            <p>« Utilisateur » : Toute personne, professionnel de santé ou patient se connectant avec un compte nominatif préalablement créé par une personne habilitée.</p>
          </li>
          <li>
            <p>« Profil » : l’ensemble des informations renseignées par l’Utilisateur relativement à sa condition physique, son traitement médical et sa prise de traitements médicamenteux.</p>
          </li>
        </ul>
        <ul>
          <li>« Compte Utilisateur » : compte créé par chaque Utilisateur, conformément à la procédure décrite à l’article 4.1 des présentes, et à partir duquel l’Utilisateur peut accéder aux Services offerts par l’Application, conformément aux CGU.</li>
        </ul>
        <h2 id="3-objet-de-la-plateforme">3. Objet de la Plateforme</h2>
        <p>L’Application permet aux Utilisateurs de renseigner des informations personnelles à des fins de suivis et de statistiques.</p>
        <p>L’Application n’a aucunement vocation à fournir, de quelque manière que ce soit, des conseils en matière de santé, ni des consultations médicales. </p>
        <h2 id="4-acceptation-des-cgu">4. Acceptation des CGU</h2>
        <p>Les CGU constituent un contrat entre l’Utilisateur et le Responsable de traitement de la Plateforme.</p>
        <p>L’accès à l’Application est conditionné par l’acceptation expresse et sans réserve par l’Utilisateur des CGU à la première connexion. L’Utilisateur est invité à dérouler les CGU puis à cliquer, s’il les accepte, sur la case « J’ai lu et j’accepte les CGU ». Par ce clic, l’Utilisateur reconnait avoir lu les CGU, les avoir acceptées.</p>
        <p>Le Responsable de traitement se réserve le droit de modifier et/ou de mettre à jour à tout moment les CGU sans préavis, ce dont sera informé l’Utilisateur. L’accès et l’utilisation de l‘Application seront soumis aux CGU en vigueur au moment de cet accès et de cette utilisation.</p>
        <p>Dans l’hypothèse où l’une des clauses des CGU serait nulle du fait d’un changement de législation ou de réglementation ou déclarée comme telle par une décision de justice définitive, cela ne saurait en aucun cas affecter la validité et le respect des autres clauses des CGU.</p>
        <h2 id="5-acc-s-la-plateforme">5. Accès à la Plateforme</h2>
        <h3 id="5-1-cr-ation-d-un-compte-utilisateur">5.1 Création d’un compte utilisateur</h3>
        <p>L’accès à l’Application nécessite la création d’un compte Utilisateur. Pour cela, l’Utilisateur doit disposer d’une adresse email valide. </p>
        <h3 id="5-2-conditions-d-acc-s-la-plateforme">5.2 Conditions d’accès à la Plateforme</h3>
        <p>L’utilisation de l’Application par l’Utilisateur nécessite une authentification forte à la Plateforme. </p>
        <p>A chaque connexion, l’Utilisateur est invité, dans un premier temps, à entrer ses identifiants de connexion (identifiant et mot de passe). </p>
        <p>
          A l’exception d’une précédente authentification par enrôlement du navigateur comme navigateur de confiance (
          <span style={{ fontStyle: 'italic' }}>browser enrollment</span>
          ), à chaque connexion sur l’Application, l’Utilisateur est invité, dans un second temps, à saisir un code temporaire à usage unique (
          <span style={{ fontStyle: 'italic' }}>One Time Password</span>
          ) généré automatiquement et aléatoirement par la Plateforme, et transmis par email.
        </p>
        <h3 id="5-3-confidentialit-des-identifiants-de-connexion">5.3 Confidentialité des identifiants de connexion</h3>
        <p>
          L’identifiant et le mot de passe sont confidentiels et strictement personnels.
          Le mot de passe doit être choisi par l’Utilisateur de façon à ce qu’il ne puisse être deviné par un tiers. L’Utilisateur devra conserver ces identifiants de connexion de manière sécurisée et s’engage à ne pas les divulguer à des tiers pour quelque raison, de quelque manière et sous quelque forme que ce soit.
          {' '}
        </p>
        <p>L’Utilisateur doit s’assurer de la déconnexion propre de l’Application à la fin de son utilisation.</p>
        <p>En cas de perte, oubli ou vol de ses éléments d’authentification, l’Utilisateur doit modifier son mot de passe dans les plus brefs délais, directement depuis son compte Utilisateur, ou en cliquant sur le bouton « Mot de passe oublié » situé sous le formulaire de saisie des identifiants de connexion. Dans ce dernier cas, un nouveau mot de passe sera adressé par email à l’Utilisateur qui s’engage à le modifier à la première connexion à la Plateforme. </p>
        <p>En cas d’oubli, de perte, d’utilisation détournée ou non autorisée de son code confidentiel par un autre Utilisateur, la responsabilité du Responsable de traitement ne pourra en aucun cas être engagée.</p>
        <p>Pour que le Patient Utilisateur puisse accéder à la plateforme, l’Utilisateur professionnel de santé devra ajouter le patient sur l’Application après vérification de l’éligibilité de ce dernier et de son accord écrit de participation. L’Utilisateur s’engage à délivrer des informations réelles et exactes.</p>
        <p>L’accès à l’Application et son utilisation par tout Utilisateur sont soumis aux présentes CGU, aux lois en vigueur concernant l’utilisation d’Internet, et plus généralement au respect de la Réglementation applicable à la protection des données personnelles.</p>
        <h3 id="5-4-disponibilit-de-la-plateforme">5.4 Disponibilité de la Plateforme</h3>
        <p>L’Application est accessible 24 heures sur 24, 7 jours sur 7, sous réserve de la survenance d’un cas de force majeure ou d’un évènement hors du contrôle du Responsable de traitement et sauf interruption, suspension ou limitation dans le cadre d’opérations de maintenance et/ou de mises à jour nécessaires au bon fonctionnement de la Plateforme.</p>
        <p>Le Responsable de traitement n’est tenu qu’à une obligation de moyens concernant l’accessibilité, le fonctionnement et la disponibilité de l’Application ou de ses contenus. Le Responsable de traitement se réserve la possibilité d’interrompre, de suspendre ou de limiter l’accès à tout ou partie de l’Application notamment en raison de contraintes d’ordre juridique ou technique. </p>
        <p>Le Responsable de traitement se réserve la possibilité d’interrompre, de suspendre momentanément ou de modifier sans préavis l’accès à tout ou partie du Service, afin d’en assurer la maintenance, ou pour tout autre raison, sans que l’interruption n’ouvre droit à aucune obligation ni indemnisation.</p>
        <h2 id="6-utilisation-de-la-plateforme">6. Utilisation de la Plateforme</h2>
        <h3 id="6-1-bon-usage-de-la-plateforme">6.1 Bon usage de la Plateforme</h3>
        <p>Compte tenu de la nature et de la finalité de la Plateforme, l’Utilisateur professionnel doit agir en qualité de professionnel, pour les besoins de son activité professionnelle et s&#39;engage à n&#39;utiliser l’Application que pour les besoins de cette activité exclusivement. </p>
        <p>L’Utilisateur professionnel s’engage à utiliser l’Application conformément aux CGU, dans le respect de ses obligations déontologiques et, plus généralement, des lois et réglementations internationales, européennes et nationales applicables en France et, le cas échéant, dans le pays où se situe l’Utilisateur. </p>
        <p>
          L’Utilisateur professionnel amené à traiter des données personnelles dans le cadre de l’utilisation de l’Application s’engage à respecter les dispositions prévues à l’article « Protection des Données Personnelles » ci-après.
          Les Données intégrées par l’Utilisateur professionnel sur l’Application sont strictement couvertes par le secret professionnel, ce que l’Utilisateur s’engage à respecter et à faire respecter aux membres de son équipe amenés à accéder aux Données.
          {' '}
        </p>
        <h3 id="6-2-comportements-prohib-s">6.2 Comportements prohibés</h3>
        <p>Il est strictement interdit d’utiliser l’Application aux fins suivantes :</p>
        <ul>
          <li>l’exercice d’activités illégales, frauduleuses ou portant atteinte aux droits ou à la sécurité des tiers,</li>
          <li>l’atteinte à l’ordre public ou la violation des lois et règlements en vigueur,</li>
          <li>l’aide ou l’incitation, sous quelque forme et de quelque manière que ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,</li>
          <li>et plus généralement toute pratique détournant l’Application à des fins autres que celles pour lesquelles elle a été conçue,</li>
          <li>de transmettre des virus, un cheval de Troie, des bombes logiques ou tout autre programme nuisible ou destructeur ;</li>
          <li>d’entraver l’Application par quelque moyen que ce soit ;</li>
          <li>de procéder ou même de tenter une intrusion du sein de l’Application ou du système d’information d’administration de la Plateforme, ou de modifier, totalement ou partiellement les éléments qui y sont contenus.</li>
        </ul>
        <p>L’Utilisateur est, en tout état de cause, exclusivement responsable de l’utilisation qu’il fait de l’Application et de ses contenus, et, de manière générale, de toute décision prise dans le cadre de son activité professionnelle, le cas échéant.</p>
        <p>Le Responsable de traitement pourra suspendre tout accès et le cas échéant résilier l’accès de l’Utilisateur à l’Application en cas de non-respect du présent article, sans préjudice de tous dommages et intérêts que pourrait solliciter l’Utilisateur.</p>
        <h2 id="7-garanties">7. Garanties</h2>
        <p>Le Responsable de traitement garantit la confidentialité et la sécurité des Informations personnelles manipulées et stockées sur la Plateforme. </p>
        <p>
          Le Responsable de traitement garantit la sécurité physique et logique des serveurs sur lesquels est hébergée la Plateforme. Il s&#39;engage à tout mettre en œuvre pour assurer l&#39;intégrité du réseau et des serveurs contre tout acte de malveillance extérieur ou toute attaque informatique connue. Les serveurs sont protégés contre les intrusions par un
          <span style={{ fontStyle: 'italic' }}> firewall</span>
          . Les mises à jour de sécurité des systèmes d&#39;exploitation et de l&#39;anti-virus sont installées automatiquement.
        </p>
        <p>Le Responsable de traitement ne saurait garantir l&#39;exactitude, l&#39;exhaustivité, l’actualité ou autre qualité des Informations personnelles intégrées et diffusées par l’Utilisateur sur la Plateforme. </p>
        <p>L’Utilisateur est seul responsable de l’intégrité, l’exactitude et la qualité des Informations personnelles qu’il intègre et diffuse sur la Plateforme.</p>
        <p>Le Responsable de traitement ne consent aucune autre garantie expresse ou implicite, en ce y compris, sans que cette énumération ne soit limitative, quant à la non violation des droits d&#39;un tiers, la continuité, performance et/ou pérennité de l’Application et/ou quant à l’aptitude à un usage particulier ou l’adéquation de l’Application aux besoins de l’Utilisateur, ni ne garantit qu’elle est exempte d’anomalies, erreurs ou bugs ou qu’elle fonctionnera sans panne ni interruption. L’Application est diffusée « en l’état » et selon sa disponibilité.</p>
        <p>L’accès à l’Application implique la connaissance et l’acceptation des caractéristiques et des limites d’internet, notamment en ce qui concerne les performances techniques, les temps de réponse pour consulter, interroger ou transférer des informations, les risques d’interruption, et plus généralement, les risques inhérents à toute connexion et transmission sur internet, l’absence de protection de certaines données contre des détournements éventuels et les risques de contamination par d’éventuels virus circulant sur le réseau. Le Responsable de traitement ne garantit pas que l’Application ou ses serveurs seront à tout moment exempts de virus, vers, chevaux de Troie ou tout autre composant susceptible de causer un dommage. Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses propres matériels, données, et/ou logiciels stockés sur son équipement informatique contre toute atteinte.</p>
        <h2 id="8-responsabilit-limitation-de-responsabilit-">8. Responsabilité &amp; limitation de responsabilité</h2>
        <h3 id="8-1-responsabilit-">8.1 Responsabilité</h3>
        <p>L’accès et l’utilisation de l’Application par l’Utilisateur ne sauraient en aucun cas engager la responsabilité du responsable de Traitement, à quelque titre que ce soit. L’accès à l’Application et son utilisation se font sous l’entière responsabilité de l’Utilisateur.</p>
        <p>L&#39;Utilisateur est seul maître de la bonne utilisation, avec discernement et esprit, de l’Application et des données qu’elle contient et, en particulier, des Informations personnelles.</p>
        <p>Dans toute la mesure autorisée par la loi applicable, le Responsable de traitement exclut expressément sa responsabilité pour tout dommage, direct ou indirect, résultant de ou en relation avec l’accès à la Plateforme, son utilisation, son dysfonctionnement ou son indisponibilité quelles qu’en soient la nature et la durée.</p>
        <p>L’Utilisateur reconnait que le Responsable de traitement demeure libre de corriger et/ou modifier l’Application à tout moment et sans préavis, sans que cette correction et/ou modification ne puisse ouvrir droit à un quelconque recours de sa part.</p>
        <p>La responsabilité du Responsable de traitement ne saurait être engagée, d’une façon générale, dans tous les cas où l&#39;inexécution ou la mauvaise exécution des CGU résulterait d&#39;un cas de force majeure ou cas fortuit indépendant de sa volonté.</p>
        <h3 id="8-2-limitations-de-responsabilit-">8.2 Limitations de responsabilité</h3>
        <p>Le recours et le maintien de tout équipement, informatique, téléphonique et autre équipement nécessaire pour accéder à l’Application sont de la responsabilité de l’Utilisateur qui en supportera seul les coûts. L’Editeur de l’Application ne sera pas tenu responsable en cas de dommage à tout équipement dans le cadre de l’utilisation de l’Application.</p>
        <p>L’Utilisateur reconnaît être seul responsable de l’utilisation des Services d’ARTH-e, de toute décision prise et de toute action mise en œuvre à partir de ces Services.</p>
        <p>L’Utilisateur est informé que les Services sont délivrés à titre strictement indicatif et ne se substituent en aucun titre à un professionnel de santé.</p>
        <p>En conséquence, l’Utilisateur reconnaît qu’ARTH-e ne pourra être tenue pour responsable de tout dommage matériel ou immatériel, direct ou indirect, quelles qu’en soient les causes ni des conséquences résultant de l’utilisation des Services à d’autres finalités qu’une finalité informationnelle, en dehors de toute intervention d’un professionnel de santé.</p>
        <h2 id="9-propri-t-intellectuelle">9. Propriété intellectuelle</h2>
        <h3 id="9-1-droits-de-propri-t-intellectuelle-sur-la-plateforme">9.1. Droits de propriété intellectuelle sur la Plateforme</h3>
        <p>L’Application est protégée par des droits de propriété intellectuelle (tels que notamment tous droits d’auteur, droits sur les brevets, les marques, droit des producteurs de bases de données, et tout autre droit de propriété intellectuelle existant ou futur, français et internationaux) et appartient au Responsable de Traitement ou à des tiers ayant autorisé le Responsable de traitement à l’exploiter.</p>
        <p>Ainsi, il est rappelé qu’au sens de l’article L 341-1 du Code de la Propriété Intellectuelle, le producteur de l’observatoire de données est entendu comme la personne qui prend l&#39;initiative et le risque des investissements correspondants.</p>
        <p>L’utilisation de l’Application ne confère en aucune façon à l’Utilisateur un droit de propriété et/ou un droit de propriété intellectuelle sur l’Application à l’exception d’un droit personnel d’accès, non exclusif et limité exclusivement à l’utilisation de l’Application conformément à son objet et ses finalités et dans le respect des CGU.</p>
        <p>Il est strictement interdit de représenter, de reproduire et/ou d’exploiter la Plateforme, totalement ou partiellement, sous quelque forme et par quelque moyen que ce soit, sans l’accord écrit et préalable du Responsable de Traitement. L’Utilisateur s’engage à ne pas copier, modifier, assembler, décompiler, altérer, vendre, louer, prêter, diffuser, distribuer ou transférer tout ou partie de la Plateforme, créer des œuvres dérivées à partir de ces œuvres, autoriser un tiers à commettre de tels actes ou lui permettre de le faire, sans l’accord écrit et préalable du Responsable de Traitement.</p>
        <p>Le non-respect des dispositions du présent article constitue une violation des droits de propriété intellectuelle du Responsable de traitement et/ou du tiers concédant et est susceptible d’entraîner des poursuites civiles et pénales.</p>
        <h3 id="9-2-droits-de-propri-t-intellectuelle-sur-la-base-de-donn-es-de-sant-">9.2 Droits de propriété intellectuelle sur la base de Données</h3>
        <p>La base de Données appartient au Responsable de Traitement. L’Utilisateur garantit le Responsable de Traitement contre toute demande, réclamation ou action de tiers, pour quelque cause et sur quelque fondement que ce soit, relativement aux Informations personnelles.</p>
        <h2 id="10-protection-des-donn-es-personnelles">10. Protection des données personnelles</h2>
        <h3 id="10-1-protection-des-donn-es-personnelles-de-l-utilisateur">10.1. Protection des données personnelles de l’Utilisateur</h3>
        <p>Le Responsable de traitement s’engage à protéger les données personnelles de l’Utilisateur, enregistrées lors de son accès et/ou utilisation de la Plateforme, conformément à la Réglementation applicable à la protection des données personnelles.</p>
        <p>Les données personnelles communiquées par l’Utilisateur sont nécessaires pour son accès à l’Application et pour son utilisation ainsi que pour répondre à ses demandes et assurer le support et la maintenance de la Plateforme. Ces informations sont strictement confidentielles et destinées exclusivement au Responsable de traitement.</p>
        <p>Le Responsable de traitement s’engage à ne pas divulguer, céder, louer ou transmettre les données personnelles de l’Utilisateur à des tiers autres que l’Hébergeur désigné à l’article « Hébergement de l’Application » ci-après et, le cas échéant, le prestataire de maintenance de l’Application (société OPENIUM ) conformément au contrat qui les lie et qui ne saurait déroger au présent article, sauf obligation légale ou judiciaire lui enjoignant de le faire.</p>
        <p>Les données personnelles de l’Utilisateur seront conservées pendant la durée d’utilisation de l’Application par l’Utilisateur et seront archivées à la clôture de son compte pendant la durée légale nécessaire au respect des obligations légales du Responsable de traitement ou aux fins de preuve pour la constatation, l’exercice ou la défense d’un droit en justice.</p>
        <p>Conformément à la Réglementation applicable à la protection des données personnelles, le Responsable de traitement s’engage à prendre toute précaution utile, au regard de la nature des données personnelles de l’Utilisateur et des risques présentés par le traitement, pour en préserver la sécurité et la confidentialité et, notamment, empêcher la destruction, la perte, l’altération, la divulgation ou l’accès non autorisé à ces données.</p>
        <p>Conformément à la Réglementation applicable à la protection des données personnelles, l’Utilisateur dispose d’un droit d’accès, de rectification, de limitation et de portabilité des données personnelles le concernant.</p>
        <p>L’Utilisateur reconnait et accepte qu’il ne dispose pas du droit à l’effacement de ses données, justifié par le respect des obligations légales mises à la charge du Responsable de traitement notamment en matière de règles d’accès et de traçabilité des accès aux Informations personnelles par les Utilisateurs.</p>
        <p>
          L’Utilisateur dispose également d’un droit d’opposition à ce que ses données personnelles fassent l’objet d’un traitement, ainsi que du droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) s’il considère que le traitement opéré par le Responsable de traitement constitue une violation de ses données personnelles. La mise en œuvre par l’Utilisateur de son droit d’opposition à ce que ses données personnelles fassent l’objet d’un traitement entraîne
          <span style={{ fontStyle: 'italic' }}> ipso facto </span>
          l’impossibilité pour l’Utilisateur d’utiliser et d’accéder à la Plateforme.
        </p>
        <p>
          L’Utilisateur peut exercer ses droits à tout moment auprès du Responsable de traitement par courriel adressé à l’adresse suivante :
          <a href="mailto:dpd@chu-clermontferrand.fr"> dpd@chu-clermontferrand.fr</a>
          .
        </p>
        <h3 id="10-2-h-bergement-de-la-plateforme">10.2. Hébergement de la Plateforme</h3>
        <p>
          L’Application est hébergée sur un serveur fourni par la société Openium, 15 rue Jean Claret - 6300 Clermont-Ferrand – Web :
          <a href="https://www.openium.fr">https://www.openium.fr</a>
          , S.A.S. au capital de 67 350 € - RCS Clermont-Ferrand 499 284 859 - APE62.01Z  - TVA Intracommunautaire Numéro TVA: FR 86 499 284 859.
        </p>
        <p>L’hébergeur agit en tant que prestataire désigné par le Responsable de traitement pour le déploiement de l’Application (société OPENIUM) au sens de la Réglementation applicable à la protection des données personnelles, sur instructions du Responsable de traitement et dans les conditions contractuelles signées entre la société Openium et le Responsable de traitement qui ne peuvent déroger au présent article.</p>
        <h3 id="11-cookies">11. Cookies</h3>
        <p>
          Pour plus d’information sur l&#39;utilisation des cookies, l’Utilisateur peut consulter la politique de confidentialité disponible en cliquant sur le lien suivant :
          <a href="https://arth-e.openium.fr/confidentialite">https://arth-e.openium.fr/confidentialite</a>
          .
        </p>
        <h3 id="12-force-majeure">12. Force majeure</h3>
        <p>La responsabilité du responsable de traitement ne pourra pas être recherchée si l’exécution de l’une de ses obligations est empêchée ou retardée en raison d’un cas de force majeure tel que défini par la jurisprudence des Tribunaux français, et notamment les catastrophes naturelles, incendies, dysfonctionnement ou interruption du réseau.</p>
        <h3 id="13-dur-e-suspension-et-r-siliation">13. Durée, suspension et résiliation</h3>
        <p>Les CGU entrent en vigueur sans réserve, à l’égard de l’Utilisateur à compter de leur acceptation à partir de la création du Compte personnel.</p>
        <p>Elles restent en vigueur durant toute la durée de la relation contractuelle de l’Utilisateur avec la Société : la cessation des présentes CGU prend effet soit au jour de la résiliation de son inscription par l’Utilisateur, soit au jour de la résiliation de son inscription par la Société, dans les conditions décrites aux CGU.</p>
        <p>MISE EN GARDE GÉNÉRALE : TOUT UTILISATEUR S’ENGAGE À RESPECTER INCONDITIONNELLEMENT ET INTÉGRALEMENT LES TERMES DES PRÉSENTES CGU SUIVANT LES MODALITÉS DÉCRITES CI-APRÈS.</p>
        <p>
          EN ACCÉDANT À L’OBSERVATOIRE ARTH-e ET EN BÉNÉFICIANT DES SERVICES ET CONTENUS,
          L’UTILISATEUR RECONNAÎT AVOIR LU ET COMPRIS L’INTÉGRALITÉ DES PRÉSENTES CGU,
          ET LES ACCEPTER SANS RESTRICTION NI RÉSERVE.
        </p>
        <p>
          L’UTILISATEUR RECONNAÎT QUE L’EXPRESSION DE SON CONSENTEMENT EST MATÉRIALISÉE PAR
          L’ACTIVATION DE CASE(S) À COCHER ASSOCIÉE(S) À UNE CONVENTION DE PREUVE DÉFINIE AUX PRÉSENTES CGU.
        </p>
        <p>
          L’ATTENTION DE L’UTILISATEUR EST ATTIRÉE SUR LE FAIT QUE L’ACCEPTATION DES PRÉSENTES CGU
          EMPORTE L’ACCEPTATION DES RÈGLES D’ACCÈS ET D’UTILISATION DE L’OBSERVATOIRE ARTH-e ET DE L’APPLICATION ARTH-e.
        </p>
        <p>
          En cas d’utilisation de l’Application par l’Utilisateur non conforme aux CGU,
          en cas de manquement de l’Utilisateur aux CGU, ou plus généralement en cas de violation des lois et réglementations applicables,
          le Responsable de traitement pourra suspendre ou résilier de plein droit, sans mise en demeure préalable, sans préavis ni indemnité,
          tout ou partie de l’accès de l’Utilisateur à la Plateforme. Le Responsable de traitement pourra prononcer cette suspension ou résiliation
          sans préjudice de tous les autres droits, actions et recours dont le Responsable de traitement pourrait disposer en vue de la réparation
          du préjudice qu’elle pourrait avoir subi du fait de ces manquements. Au terme des CGU pour quelque raison que ce soit, le compte Utilisateur sera inactivé.
        </p>
        <h3 id="14-droit-applicable-et-attribution-de-juridiction-">14. Droit applicable et attribution de juridiction</h3>
        <p>Les CGU seront exécutées et interprétées conformément au droit français. Les parties déclarent leur intention de chercher une solution amiable à toute difficulté qui pourrait surgir à propos de la validité, de l’interprétation ou de l’exécution des CGU. Dans les limites permises par la loi, en cas de désaccord persistant, le litige sera soumis aux tribunaux compétents de Clermont-Ferrand.</p>
        <h3 id="15-documents-contractuels">15. DOCUMENTS CONTRACTUELS</h3>
        <p>
          Les CGU, la politique de confidentialité des données à caractère personnel concernant l’Utilisateur [
          <a href="https://arth-e.openium.fr/confidentialite">https://arth-e.openium.fr/confidentialite</a>
          ] constituent les dispositions contractuelles encadrant l’utilisation de l’Application pour la fourniture des Services et Contenus.
          {' '}
        </p>
        <p>Si une disposition des présentes CGU était jugée invalide par un tribunal compétent, l&#39;invalidité de cette disposition n&#39;en affecterait pas pour autant la validité des autres dispositions des présentes CGU.</p>
        <p>Aucune renonciation à l&#39;une des dispositions de ces CGU ne peut être considérée comme valant renonciation définitive à cette disposition ou à d’autres dispositions des présentes CGU.</p>
      </Container>
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

const Checkmark = ({
  color,
  width,
  height,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Eva-icons/checkmark">
      <path
        id="&#240;&#159;&#142;&#168; Icon &#208;&#161;olor"
        d="M9.86329 18C9.58729 18 9.32329 17.886 9.13429 17.685L4.27129 12.506C3.89229 12.104 3.91329 11.471 4.31529 11.093C4.71829 10.715 5.35129 10.735 5.72829 11.137L9.85329 15.528L18.2613 6.326C18.6353 5.917 19.2673 5.89 19.6753 6.262C20.0823 6.634 20.1103 7.267 19.7383 7.674L10.6013 17.674C10.4143 17.88 10.1483 17.998 9.87029 18H9.86329Z"
        fill={color}
      />
    </g>
  </svg>
);
Checkmark.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
Checkmark.defaultProps = {
  color: 'currentColor',
  width: 25,
  height: 25,
};

export default Checkmark;

import React from 'react';
import PropTypes from 'prop-types';

const IcDirectionsRun = ({
  color,
  width,
  height,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Material-icons/ic_directions_run">
      <path
        id="Mask"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.98 6.95996C30.98 9.15996 29.18 10.96 26.98 10.96C24.78 10.96 22.98 9.15996 22.98 6.95996C22.98 4.75996 24.78 2.95996 26.98 2.95996C29.18 2.95996 30.98 4.75996 30.98 6.95996ZM21.78 29.96L19.78 38.76L5.78003 35.96L6.58003 31.96L16.38 33.96L19.58 17.76L15.98 19.16V25.96H11.98V16.56L22.38 12.16C22.68 12.16 22.93 12.11 23.18 12.06C23.43 12.01 23.68 11.96 23.98 11.96C25.38 11.96 26.58 12.76 27.38 13.96L29.38 17.16C30.98 19.96 34.18 21.96 37.98 21.96V25.96C33.58 25.96 29.58 23.96 26.98 20.96L25.78 26.96L29.98 30.96V45.96H25.98V33.96L21.78 29.96Z"
        fill={color}
      />
    </g>
  </svg>
);
IcDirectionsRun.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
IcDirectionsRun.defaultProps = {
  color: 'currentColor',
  width: 25,
  height: 25,
};

export default IcDirectionsRun;

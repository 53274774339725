import React, { useState, useEffect, useContext } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Image } from '@mui/icons-material';
import CardMedia from '@mui/material/CardMedia';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CardActions from '@mui/material/CardActions';
import { Card, CardContent, FormHelperText } from '@mui/material';
import { postQuestion, putQuestion } from '../../store/question/action';
import { QUESTIONS } from '../../constants/routes';
import { minInputWidth } from '../../constants/stylesDefault';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  dropZone: {
    marginLeft: 20,
    marginTop: 20,
  },
  questionTextField: {
    width: 300,
  },
  dropzoneGridItem: {
    minWidth: minInputWidth,
    width: '40vw',
    maxWidth: '100%',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  cardcontent: {
    textAlign: 'center',
  },
  imgpic: {
    margin: 'auto',
    marginTop: '8px',
  },
  quill: {
    minWidth: minInputWidth,
    width: '40vw',
    maxWidth: '100%',
  },
});

function QuestionForm({
  question,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sending } = useSelector((state) => state.question);
  const { notifyApiError } = useContext(NotificationContext);
  const { token } = useUserAuth();

  const [id, setId] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [questionTextError, setQuestionTextError] = useState('');
  const [answer, setAnswer] = useState(false);
  const [affirmation, setAffirmation] = useState('');
  const [affirmationError, setAffirmationError] = useState('');
  const [description, setDescription] = useState('');
  const [theme, setTheme] = useState('');
  const [themeError, setThemeError] = useState('');
  const [picture, setPicture] = useState(null);
  const [picturePath, setPicturePath] = useState('');
  const [deletePicture, setDeletePicture] = useState(false);

  useEffect(() => {
    if (question) {
      setId(question.id);
      setQuestionText(question.question);
      setAnswer(question.answer);
      setAffirmation(question.affirmation);
      setDescription(question.description);
      setTheme(question.theme);
      setPicturePath(question.image_path);
      setDeletePicture(question.delete_picture);
    }
  }, [question]);

  const isEditing = id !== '';

  const supportedDropFiles = [
    'image/bmp',
    'image/x-ms-bmp',
    'image/png',
    'image/x-icon',
    'image/vnd.microsoft.icon',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
  ].toString();

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let errorCount = 0;
    if (questionText.trim() === '') {
      errorCount++;
      setQuestionTextError('Champ obligatoire');
    }
    if (theme.trim() === '') {
      errorCount++;
      setThemeError('Champ obligatoire');
    }
    if (affirmation.trim() === '') {
      errorCount++;
      setAffirmationError('Champ obligatoire');
    }
    if (errorCount === 0 && token !== null) {
      const data = {
        id,
        question: questionText,
        answer,
        affirmation,
        description,
        theme,
        picture,
        picture_path: picturePath,
        delete_picture: deletePicture,
      };
      const formData = new FormData();
      formData.append('content', JSON.stringify(data));
      if (picture) {
        formData.append('image', picture);
      }
      if (isEditing) {
        dispatch(putQuestion(token, question.id, formData))
          .then(() => navigate(QUESTIONS))
          .catch((error) => notifyApiError(error));
      } else {
        dispatch(postQuestion(token, formData))
          .then(() => navigate(QUESTIONS))
          .catch((error) => notifyApiError(error));
      }
    }
  };

  const handleMediaDrop = (accepted, rejected) => {
    if (!rejected.length) {
      setPicturePath(URL.createObjectURL(accepted[0]));
      setPicture(accepted[0]);
    }
  };

  const handleDeleteMedia = () => {
    setPicturePath('');
    setPicture(null);
    setDeletePicture(true);
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            id="question"
            type="string"
            label="Question"
            onChange={(e) => {
              setQuestionText(e.target.value);
              setQuestionTextError('');
            }}
            value={questionText}
            multiline
            error={questionTextError !== ''}
            helperText={questionTextError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <FormControl variant="standard" error={themeError !== ''}>
            <InputLabel id="labelTheme" error={themeError !== ''}>
              Theme
            </InputLabel>
            <Select
              labelId="labelTheme"
              id="theme"
              value={theme}
              onChange={(e) => {
                setTheme(e.target.value);
                setThemeError('');
              }}
              error={themeError !== ''}
            >
              <MenuItem value="Causes">Causes</MenuItem>
              <MenuItem value="Diagnostique">Diagnostique</MenuItem>
              <MenuItem value="Généralités">Généralités</MenuItem>
              <MenuItem value="Prise en charge de l'arthrose">
                {'Prise en charge de l\'arthrose'}
              </MenuItem>
              <MenuItem value="Symptômes">Symptômes</MenuItem>
            </Select>
            <FormHelperText error={themeError !== ''}>
              {themeError}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="standard">
            <InputLabel id="labelAnswer">Réponse</InputLabel>
            <Select
              labelId="labelAnswer"
              id="answer"
              value={answer
                ? 1
                : 0}
              onChange={(e) => {
                setAnswer(Boolean(e.target.value));
              }}
            >
              <MenuItem value={1}>Vrai</MenuItem>
              <MenuItem value={0}>Faux</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            id="affirmation"
            label="Affirmation"
            onChange={(e) => {
              setAffirmation(e.target.value);
              setAffirmationError('');
            }}
            value={affirmation}
            multiline
            fullWidth
            error={affirmationError !== ''}
            helperText={affirmationError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Description
          </Typography>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={(value) => setDescription(value)}
            className={classes.quill}
          />
        </Grid>
        <Grid item className={classes.dropzoneGridItem}>
          <Typography variant="body1">
            {picturePath
              ? 'Image de la question actuelle'
              : 'Glisser votre image dans cette zone'}
          </Typography>
          <Dropzone
            onDrop={handleMediaDrop}
            accept={supportedDropFiles}
            disabled={picturePath !== null && picturePath !== ''}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Card variant="outlined">
                    <CardContent className={classes.cardcontent}>
                      {picturePath
                        ? (
                          <CardMedia
                            component="img"
                            image={picturePath}
                            title="Image"
                            className={classes.imgpic}
                          />
                        )
                        : (
                          <Image className={classes.imgpic} />
                        )}
                    </CardContent>
                    {picturePath
                      && (
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={handleDeleteMedia}
                            variant="outlined"
                          >
                            {'Supprimer l\'image'}
                          </Button>
                        </CardActions>
                      )}
                  </Card>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>

        <Grid item style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={sending}
          >
            {isEditing
              ? 'Editer'
              : 'Valider'}
            {sending && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

QuestionForm.propTypes = {
  question: PropTypes.object,
};

QuestionForm.defaultProps = {
  question: null,
};

export default QuestionForm;

import React from 'react';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '../components/Menu/Menu';
import { useUserAuth } from '../contexts/UserAuthContext.js';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 40,
  },
  containerStyle: {
    width: '100%',
    maxWidth: '1200px',
    textAlign: 'justify',
    wordWrap: 'break-word',
  },
  withMenu: {
    marginLeft: '260px',
  },
});

/* eslint-disable max-len */
export default function LegalNoticesPage() {
  const classes = useStyles();
  const { token } = useUserAuth();
  return (
    <Container maxWidth={false} className={classes.root}>
      {token !== null
        ? <Menu />
        : ''}
      <Container
        maxWidth={false}
        className={`${classes.containerStyle} ${token ? classes.withMenu : ''}`}
      >
        <h1 id="mentions-l-gales-d-arth-e">Mentions Légales d&#39;ARTH-e</h1>
        <h3 id="-diteur-de-l-application-">Éditeur de l&#39;application:</h3>
        <p>
          <strong>CHU de CLERMONT-FERRAND</strong>
          , Etablissement de santé.
        </p>
        <p>58 rue Montalembert 63000 CLERMONT-FERRAND.</p>
        <p>Tel : (+33) 04 73 75 33 33.</p>
        <h3 id="op-rateur-de-l-application-">Opérateur de l&#39;application:</h3>
        <p><strong>OPENIUM.</strong></p>
        <p>15 rue Jean Claret, Parc Technologique La Pardieu - 63000 Clermont-Ferrand.</p>
        <p>N° Siren : 499284859.</p>
        <h2 id="contexte-d-tude">Contexte d’étude</h2>
        <p>L’arthrose est une pathologie fréquente touchant l’ensemble des articulations, entrainant des douleurs chroniques et une invalidité progressive. Des études récentes montrent que l’activité physique régulière est une des clés majeures du traitement de l’arthrose, retardant la destruction articulaire et diminuant les douleurs. Les objets connectés liés à la santé, et notamment les applications Smartphone font partie des moyens émergents, tournés vers le suivi en pathologie chronique. Ils sont aussi bien une aide motivationnelle pour le patient, qu’un moyen fiable de suivi pour le praticien. Pour cela, le développement de l’outil centré sur le patient est une priorité.</p>
        <h2 id="droits-de-propri-t-intellectuelle">Droits de Propriété Intellectuelle</h2>
        <p>Tous les éléments (textes, logos, images, éléments sonores, logiciels, icônes, mise en page, base de données,...) contenus dans cette application sont protégés par le droit national et international de la propriété intellectuelle. Ces éléments restent la propriété exclusive du CHU et/ou de ses partenaires.</p>
        <p>A ce titre, sauf autorisation préalable et écrite du CHU et/ou de ses partenaires, vous ne pouvez procéder à une quelconque reproduction, représentation, adaptation, traduction et/ou transformation partielle ou intégrale, ou un transfert sur un autre site web de tout élément composant cette application.</p>
        <p>Le non-respect de cette interdiction peut constituer un acte de contrefaçon engageant vos responsabilités civile et pénale. Conformément aux dispositions du Code de la propriété Intellectuelle, seule est autorisée l&#39;utilisation des éléments composant l’application à des fins strictement personnelles.</p>
        <p>Les marques et logos reproduits sur ce site sont déposés par les sociétés qui en sont propriétaires. Toute reproduction, réédition ou redistribution des noms ou logos, par quelque moyen que ce soit, sans autorisation préalable et écrite de leur titulaire concerné est interdite par la loi.</p>
        <p>Si vous utilisez l&#39;une des marques du CHU (logos, slogans) sur votre site web, dans une publicité, dans un article de livre, ou reproduisez ladite marque ailleurs, vous devez d&#39;abord obtenir l&#39;autorisation du CHU.</p>
        <h2 id="liens-hypertextes">Liens hypertextes</h2>
        <p>Le CHU décline toute responsabilité quant au contenu des sites proposés en liens. </p>
        <p>La mise en place de liens hypertextes vers des pages ou des documents diffusés sur l’application est autorisée si les liens garantissent la possibilité d&#39;identifier l&#39;origine CHU du document. L&#39;utilisateur devra en informer préalablement le CHU. Toute utilisation d&#39;information doit faire l&#39;objet d&#39;une demande d&#39;autorisation, qui peut être sollicitée auprès du CHU. Celle-ci sera accordée si la demande ne contrevient pas aux intérêts du CHU. Le CHU se réserve le droit de poursuivre toute personne qui ne respecterait pas l&#39;application de ces règles.</p>
        <h2 id="donn-es-personnelles">Données personnelles</h2>
        <p>Informatique et Libertés : Vous disposez d&#39;un droit d&#39;accès, de modification, de rectification et de suppression des données qui vous concernent (art. 34 de la loi &quot;Informatique et Libertés&quot;). Pour l&#39;exercer, contactez la Direction de la Recherche Clinique et de l’Innovation (DRCI), CHU de Clermont-Ferrand, Projet ARTH-e, 58 rue Montalembert, 63000 Clermont-Ferrand.</p>
        <p>Le responsable du traitement est le CHU de Clermont-Ferrand, Projet ARTH-e, 58 rue Montalembert, 63000 Clermont-Ferrand.</p>
        <p>
          Pour toute information, écrivez à
          <a href="mailto:arthe@chu-clermontferrand.fr"> arthe@chu-clermontferrand.fr</a>
          .
        </p>
      </Container>
    </Container>
  );
}

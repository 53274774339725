import {
  EXERCISE_ENCOURAGEMENT_MESSAGE_REMOVED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_FAILED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_PENDING,
  EXERCISE_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_SENDING,
  EXERCISE_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
} from './types';
import {
  apiDeleteExerciseEncouragementMessage,
  apiGetExerciseEncouragementMessages,
  apiPostExerciseEncouragementMessage,
  apiPutExerciseEncouragementMessage,
} from '../../api/ExerciseEncouragementMessageApi';

export const exerciseEncouragementMessagesDownloadedAction = (exerciseEncouragementMessage) => (
  {
    type: EXERCISE_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
    payload: exerciseEncouragementMessage,
  }
);
export const exerciseEncouragementMessageDownloadedAction = (exerciseEncouragementMessage) => (
  {
    type: EXERCISE_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
    payload: exerciseEncouragementMessage,
  }
);
export const exerciseEncouragementMessagePendingAction = () => (
  {
    type: EXERCISE_ENCOURAGEMENT_MESSAGE_PENDING,
  }
);
export const exerciseEncouragementMessageSendingAction = () => (
  {
    type: EXERCISE_ENCOURAGEMENT_MESSAGE_SENDING,
  }
);
export const exerciseEncouragementMessageRemovedAction = (exerciseEncouragementMessage) => (
  {
    type: EXERCISE_ENCOURAGEMENT_MESSAGE_REMOVED,
    payload: exerciseEncouragementMessage,
  }
);
export const exerciseEncouragementMessageFailedAction = (error) => (
  {
    type: EXERCISE_ENCOURAGEMENT_MESSAGE_FAILED,
    payload: error,
  }
);

export function getExerciseEncouragementMessages(token) {
  return (dispatch) => {
    dispatch(exerciseEncouragementMessagePendingAction());
    return apiGetExerciseEncouragementMessages(token)
      .then((result) => {
        dispatch(exerciseEncouragementMessagesDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(exerciseEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

export function postExerciseEncouragementMessage(token, data) {
  return (dispatch) => {
    dispatch(exerciseEncouragementMessageSendingAction());
    return apiPostExerciseEncouragementMessage(token, data)
      .then((result) => {
        dispatch(exerciseEncouragementMessageDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(exerciseEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

export function putExerciseEncouragementMessage(token, id, data) {
  return (dispatch) => {
    dispatch(exerciseEncouragementMessageSendingAction());
    return apiPutExerciseEncouragementMessage(token, id, data)
      .then((result) => {
        dispatch(exerciseEncouragementMessageDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(exerciseEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

export function deleteExerciseEncouragementMessage(token, exerciseEncouragementMessage) {
  return (dispatch) => {
    dispatch(exerciseEncouragementMessageSendingAction());
    return apiDeleteExerciseEncouragementMessage(token, exerciseEncouragementMessage.id)
      .then(() => {
        dispatch(exerciseEncouragementMessageRemovedAction(exerciseEncouragementMessage));
        return exerciseEncouragementMessage;
      })
      .catch((error) => {
        dispatch(exerciseEncouragementMessageFailedAction(error));
        throw error;
      });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogContentText } from '@mui/material';
import useStyles from './ValidationDialog.style';

const ValidationDialog = (props) => {
  const {
    title,
    text,
    validationText,
    cancelText,
    openDialog,
    handleClose,
    sending,
    handleAction,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={handleClose}
          disabled={sending}
          variant="outlined"
        >
          {cancelText}
        </Button>
        <Button
          className={classes.button}
          onClick={handleAction}
          disabled={sending}
          variant="contained"
          color="primary"
        >
          {validationText}
          {sending && <CircularProgress size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ValidationDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  validationText: PropTypes.string,
  cancelText: PropTypes.string,
  sending: PropTypes.bool,
};

ValidationDialog.defaultProps = {
  sending: false,
  validationText: 'Oui',
  cancelText: 'Non',
  title: 'Attention',
};

export default ValidationDialog;

import { createTheme } from '@mui/material/styles';
import {
  artheDarkBlue,
  artheBlue,
  artheBlueHover,
  artheRed,
  artheWhite,
  artheNuance300,
  artheBlueShadow,
  artheButtonShadow,
} from '../constants/colors';
import { minInputWidth } from '../constants/stylesDefault';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: artheBlue,
      light: artheBlue,
      dark: artheBlueHover,
      contrastText: artheDarkBlue,
    },
    secondary: {
      main: artheDarkBlue,
      contrastText: artheWhite,
    },
    success: {
      main: artheDarkBlue,
    },
    error: {
      main: artheRed,
    },
    warning: {
      main: artheBlue,
    },
    info: {
      main: artheBlue,
    },
  },
  typography: {
    fontFamily: [
      'Mulish',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '40px',
      color: artheDarkBlue,
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      color: artheDarkBlue,
    },
    body1: {
      fontWeight: 'inherit',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      color: artheNuance300,
    },
  },
  components: {

    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-hover': {
            color: artheBlue,
          },
          '&.Mui-selected': {
            color: `${artheBlue} !important`,
            backgroundColor: '#00000014',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: minInputWidth,
          maxWidth: '100%',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          maxWidth: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: minInputWidth,
          maxWidth: '100%',
          marginTop: '16px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          minWidth: minInputWidth,
          width: '40vw',
          maxWidth: '100%',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          minWidth: minInputWidth,
          width: '40vw',
          maxWidth: '100%',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: artheBlue,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        elevation1: {
          boxShadow: artheBlueShadow,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: artheNuance300,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginTop: '20px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: artheWhite,
        },
        body: {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: artheButtonShadow,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          width: 260,
          backgroundColor: artheDarkBlue,
          color: 'white',
        },
      },
    },
  },
});

export default theme;

import {
  QUESTIONS_DOWNLOADED,
  QUESTION_SENDING,
  QUESTION_REMOVED,
  QUESTION_PENDING,
  QUESTION_FAILED,
  QUESTION_DOWNLOADED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: null,
  sending: null,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case QUESTION_SENDING:
      return {
        ...state,
        sending: true,
      };
    case QUESTIONS_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case QUESTION_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case QUESTION_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case QUESTION_DOWNLOADED: {
      const questionDownloaded = action.payload;
      let questionList = [];
      let questionIndex = -1;
      if (state.content) {
        questionList = state.content.slice();
        questionIndex = questionList.findIndex((question) => question.id === questionDownloaded.id);
      }
      if (questionIndex === -1) {
        questionList.push(action.payload);
      } else {
        questionList[questionIndex] = action.payload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: questionList,
      };
    }
    case QUESTION_REMOVED: {
      const question = action.payload;
      let questionList = state.content;
      if (questionList !== null) {
        // eslint-disable-next-line func-names
        const isQuestion = (questionId) => function (element) {
          return element.id !== questionId;
        };
        questionList = state.content.filter(isQuestion(question.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: questionList,
      };
    }
    default:
      break;
  }
  return state;
}

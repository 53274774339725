import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import QuestionForm from '../../components/forms/QuestionForm';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import { getQuestions } from '../../store/question/action';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

export default function EditQuestionPage() {
  const { questionId } = useParams();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.question);
  const dispatch = useDispatch();
  const [question, setQuestion] = useState(null);
  const { token } = useUserAuth();

  useEffect(() => {
    if (content !== null) {
      setQuestion(content.find(
        (q) => q.id === questionId,
      ));
    }
  }, [content]);

  if (!content && !pending && !error && token !== null) {
    dispatch(getQuestions(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Modifier une question" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <QuestionForm
            question={question}
          />
        )}
    </Container>
  );
}

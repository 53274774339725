import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles from './PageCircularProgress.style';

const PageCircularProgress = () => {
  const classes = useStyles();
  return (<CircularProgress size={24} className={classes.loading} />);
};

export default PageCircularProgress;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import AdviceForm from '../../components/forms/AdviceForm';
import { getAdvices } from '../../store/advice/action';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

export default function EditAdvicePage() {
  const { adviceId } = useParams();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.advice);
  const dispatch = useDispatch();
  const [advice, setAdvice] = useState(null);
  const { token } = useUserAuth();

  useEffect(() => {
    if (content !== null) {
      setAdvice(content.find(
        (a) => a.id === adviceId,
      ));
    }
  }, [content]);

  if (!content && !pending && !error && token !== null) {
    dispatch(getAdvices(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Modifier un conseil" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <AdviceForm
            advice={advice}
          />
        )}
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { PROGRAM_EDIT } from '../../constants/routes';
import { artheNuance100 } from '../../constants/colors';

const useStyles = makeStyles({
  image: {
    display: 'block',
    maxWidth: 230,
    maxHeight: 95,
    width: 'auto',
    height: 'auto',
  },
  tableRow: {
    height: 95,
    width: '100%',
    cursor: 'grab',
  },
  tableCell: {
    height: 95,
  },
});

const StrictModeDroppable = ({
  handleToggleDialog,
  content,
  tableRef,
  sending,
}) => {
  const [enabled, setEnabled] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const handleEdit = (program) => {
    navigate(PROGRAM_EDIT.replace(':programId', program.id));
  };

  useEffect(() => {
    // used to fix the table's cells width for drag and drop
    if (!tableRef.current) {
      return;
    }
    const tableCellWidth = document.querySelector('#programTable').offsetWidth / 4;
    const tableCells = document.querySelectorAll('#programTableCell');
    tableCells.forEach((tableCell) => {
      // eslint-disable-next-line no-param-reassign
      tableCell.style.width = `${tableCellWidth}px`;
    });
  });

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }
  return (
    <Droppable droppableId="1" direction="vertical">
      {(provided) => (
        <TableBody
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {content.map((program, index) => (
            <Draggable
              key={program.id}
              draggableId={program.id}
              index={index}
              isDragDisabled={sending}
            >
              {(providedDrag, snapshot) => (
                <TableRow
                  className={classes.tableRow}
                  key={program.id}
                  ref={providedDrag.innerRef}
                  {...providedDrag.draggableProps}
                  {...providedDrag.dragHandleProps}
                  style={{
                    ...providedDrag.draggableProps.style,
                    background: snapshot.isDragging
                      ? artheNuance100
                      : 'none',
                  }}
                >
                  <TableCell id="programTableCell" className={classes.tableCell}>
                    {program.title}
                  </TableCell>
                  <TableCell id="programTableCell" className={classes.tableCell}>
                    <img src={program.image_url} alt="" className={classes.image} />
                  </TableCell>
                  <TableCell id="programTableCell" className={classes.tableCell}>
                    {program.level}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                    id="programTableCell"
                  >
                    <IconButton
                      id="edit"
                      aria-label="update"
                      onClick={() => handleEdit(program)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="delete"
                      aria-label="update"
                      onClick={() => handleToggleDialog(program)}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      id="drag"
                      aria-label="drag"
                      color="primary"
                      disabled
                    >
                      <DragIndicatorIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </TableBody>
      )}
    </Droppable>
  );
};

StrictModeDroppable.propTypes = {
  content: PropTypes.array,
  tableRef: PropTypes.object.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
};

StrictModeDroppable.defaultProps = {
  content: [],
};

export default StrictModeDroppable;

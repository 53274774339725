import {
  EXERCISE_REMOVED,
  EXERCISE_DOWNLOADED,
  EXERCISE_FAILED,
  EXERCISE_PENDING,
  EXERCISES_DOWNLOADED,
  EXERCISE_SENDING,
} from './types';
import {
  apiDeleteExercise,
  apiGetExercises,
  apiPostExercise,
  apiPutExercise,
} from '../../api/ExerciseApi';

export const exercisesDownloadedAction = (exercise) => (
  {
    type: EXERCISES_DOWNLOADED,
    payload: exercise,
  }
);
export const exerciseDownloadedAction = (exercise) => (
  {
    type: EXERCISE_DOWNLOADED,
    payload: exercise,
  }
);
export const exercisePendingAction = () => (
  {
    type: EXERCISE_PENDING,
  }
);
export const exerciseSendingAction = () => (
  {
    type: EXERCISE_SENDING,
  }
);
export const exerciseRemovedAction = (exercise) => (
  {
    type: EXERCISE_REMOVED,
    payload: exercise,
  }
);
export const exerciseFailedAction = (error) => (
  {
    type: EXERCISE_FAILED,
    payload: error,
  }
);

export function getExercises(token) {
  return (dispatch) => {
    dispatch(exercisePendingAction());
    return apiGetExercises(token)
      .then((result) => {
        dispatch(exercisesDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(exerciseFailedAction(error));
        throw error;
      });
  };
}

export function postExercise(token, data) {
  return (dispatch) => {
    dispatch(exerciseSendingAction());
    return apiPostExercise(token, data)
      .then((result) => {
        dispatch(exerciseDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(exerciseFailedAction(error));
        throw error;
      });
  };
}

export function putExercise(token, id, data) {
  return (dispatch) => {
    dispatch(exerciseSendingAction());
    return apiPutExercise(token, id, data)
      .then((result) => {
        dispatch(exerciseDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(exerciseFailedAction(error));
        throw error;
      });
  };
}

export function deleteExercise(token, exercise) {
  return (dispatch) => {
    dispatch(exerciseSendingAction());
    return apiDeleteExercise(token, exercise.id)
      .then(() => {
        dispatch(exerciseRemovedAction(exercise));
        return exercise;
      })
      .catch((error) => {
        dispatch(exerciseFailedAction(error));
        throw error;
      });
  };
}

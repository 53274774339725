import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  ENV_DEV,
  ENV_LOCAL,
} from '../constants/env';

// eslint-disable-next-line import/no-mutable-exports
let firebaseConfig = {
  apiKey: 'AIzaSyD4KpAm_5ka_Vm5QN4wwDHEEiG-PYgafsY',
  authDomain: 'arth-e-prod.firebaseapp.com',
  projectId: 'arth-e-prod',
  storageBucket: 'arth-e-prod.appspot.com',
  messagingSenderId: '817160793701',
  appId: '1:817160793701:web:ed4565a291e52bcf54208c',
  measurementId: 'G-3FRJ0F6J0Y',
};

if (process.env.REACT_APP_ENV) {
  if (
    process.env.REACT_APP_ENV === ENV_LOCAL
    || process.env.REACT_APP_ENV === ENV_DEV
  ) {
    firebaseConfig = {
      apiKey: 'AIzaSyCylhwMga3bJfr2x8K5rsD492zPyrYwTig',
      authDomain: 'arth-e-dev.firebaseapp.com',
      databaseURL: 'https://arth-e-dev.firebaseio.com',
      projectId: 'arth-e-dev',
      storageBucket: 'arth-e-dev.appspot.com',
      messagingSenderId: '497728667591',
      appId: '1:497728667591:web:e616af1ab7c8d2202663e2',
    };
  }
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;

export const getHeaders = (token = null) => {
  const header = {
    Accept: 'application/json',
  };
  if (token) {
    header.Authorization = `Bearer ${token}`;
  }
  return header;
};

export default {
  getHeaders,
};

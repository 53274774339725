import React, { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import { Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CheckBox from '@mui/material/Checkbox';
import {
  ProgramExerciseContext,
} from '../../contexts/ProgramExerciseContext';
import { artheBlue, artheNuance200, artheNuance300 } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    exercisesNumberBox: {
      backgroundColor: artheBlue,
      padding: '4px 10px',
      borderRadius: '5px',
      width: 'fit-content',
    },
    exercisesNumberBoxDisabled: {
      backgroundColor: artheNuance200,
      color: artheNuance300,
    },
  }
));

const ProgramExerciceTableForm = ({
  exerciseList,
  isSelected,
  handleClick,
  last,
  numberOfNeededExercises,
}) => {
  const classes = useStyles();

  const {
    checkedExercisesNumber,
    setCheckedExercisesNumber,
    changeProgramExercisesNumber,
  } = useContext(ProgramExerciseContext);

  // Used to load checked exercises
  useEffect(() => {
    setCheckedExercisesNumber(exerciseList.filter((exercise) => isSelected(exercise)).length);
  }, [exerciseList]);

  if (exerciseList.length <= 0) {
    return null;
  }
  let tableTitle = 'Exercices';
  // eslint-disable-next-line default-case
  switch (exerciseList[0].type) {
    case 'CARDIO':
      tableTitle = 'Echauffement cardiovasculaire';
      break;
    case 'ARTI':
      tableTitle = 'Echauffement articulaire';
      break;
    case 'MUSCU':
      tableTitle = 'Renforcement musculaire';
      break;
    case 'PROPRIO':
      tableTitle = 'Proprioception';
      break;
    case 'STRETCH':
      tableTitle = 'Etirement';
      break;
  }

  return (
    <Grid
      item
      sx={12}
      md={last
        ? 12
        : 6}
      style={{ paddingTop: 0 }}
    >
      <TableContainer component={Paper} style={{ width: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: '80px' }}>
                <Typography
                  className={checkedExercisesNumber === 0
                    ? `${classes.exercisesNumberBoxDisabled} ${classes.exercisesNumberBox}`
                    : classes.exercisesNumberBox}
                >
                  {checkedExercisesNumber}
                  {' '}
                  /
                  {' '}
                  {numberOfNeededExercises}
                </Typography>
              </TableCell>
              <TableCell align="left">{tableTitle}</TableCell>
              <TableCell align="right">Niveau</TableCell>
              <TableCell align="right">Répétitions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exerciseList.map((exercise) => (
              <TableRow key={exercise.id}>
                <TableCell>
                  <CheckBox
                    color="primary"
                    checked={isSelected(exercise)}
                    onChange={(event, checked) => {
                      handleClick(exercise);
                      changeProgramExercisesNumber(checked);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  {exercise.title}
                </TableCell>
                <TableCell align="right">
                  {exercise.level}
                </TableCell>
                <TableCell align="right">
                  {exercise.nb_repetition}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
ProgramExerciceTableForm.propTypes = {
  exerciseList: PropTypes.array.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  last: PropTypes.bool,
  numberOfNeededExercises: PropTypes.number.isRequired,
};

ProgramExerciceTableForm.defaultProps = {
  last: false,
};

export default ProgramExerciceTableForm;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import CongratulationsMessageForm
  from '../../../components/forms/CongratulationsMessageForm';
import {
  getCongratulationsMessages,
} from '../../../store/congratulationsMessage/action';
import PageCircularProgress from '../../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../../contexts/UserAuthContext.js';

export default function EditCongratulationsMessagePage() {
  const { congratulationsMessageId } = useParams();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.congratulationsMessage);
  const dispatch = useDispatch();
  const { token } = useUserAuth();

  const [congratulationsMessage, setCongratulationsMessage] = useState(null);
  useEffect(() => {
    if (content !== null) {
      setCongratulationsMessage(content.find(
        (message) => message.id === congratulationsMessageId,
      ));
    }
  }, [content]);

  if (!content && !pending && !error && token !== null) {
    dispatch(getCongratulationsMessages(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Modifier un encouragement lors des exercices" />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {congratulationsMessage !== null
        && (
          <CongratulationsMessageForm
            congratulationsMessage={congratulationsMessage}
          />
        )}
    </Container>
  );
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';
import Menu from '../components/Menu/Menu';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    paddingLeft: 260,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 40,
  },
});

export default function HomePage() {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.root}>
      <Menu />
      <Outlet />
    </Container>
  );
}

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Divider,
  Drawer,
  useTheme,
  MenuItem,
  Typography,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  ADVICES,
  EXERCISES,
  PROGRAMS,
  QUESTIONS,
  ENCOURAGEMENT,
  PRIVACY,
  CGU,
  LEGAL_NOTICES,
} from '../../constants/routes';
import { apiGetCsv } from '../../api/CsvApi';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

const useStyles = makeStyles((theme) => (
  {
    menu: {
      overflowY: 'auto',
      height: 'auto',
      paddingRight: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    logOut: {
      color: 'white',
    },
    menuItemList: {
      marginTop: 20,
      marginBottom: 20,
    },
    menuLogo: {
      width: 70,
      margin: '10px auto 0 auto',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }
));

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    logOut,
    token,
  } = useUserAuth();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
  };
  return (
    <div className={classes.menu}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.root}
      >
        <img
          className={classes.menuLogo}
          src={`${process.env.PUBLIC_URL}/ARTH-eMenu.svg`}
          alt="menu logo"
        />
        <div className={classes.menuItemList}>
          <Divider className={classes.divider} />
          <MenuItem
            component={Link}
            to={ADVICES}
            selected={location.pathname === ADVICES}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Conseils'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={EXERCISES}
            selected={location.pathname === EXERCISES}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Exercices'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={PROGRAMS}
            selected={location.pathname === PROGRAMS}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Programmes'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={ENCOURAGEMENT}
            selected={location.pathname.includes(ENCOURAGEMENT)}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Encouragements'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={QUESTIONS}
            selected={location.pathname === QUESTIONS}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Questions'}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              apiGetCsv(token);
            }}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Export CSV'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={LEGAL_NOTICES}
            selected={location.pathname === LEGAL_NOTICES}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Mentions légales'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={CGU}
            selected={location.pathname === CGU}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> C.G.U'}
            </Typography>
          </MenuItem>
          <MenuItem
            component={Link}
            to={PRIVACY}
            selected={location.pathname === PRIVACY}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Politique de confidentialité'}
            </Typography>
          </MenuItem>
          <Divider className={classes.divider} />
          <MenuItem
            onClick={handleLogout}
          >
            <Typography style={{ marginLeft: 30 }}>
              {'> Déconnexion'}
            </Typography>
          </MenuItem>
        </div>
      </Drawer>
    </div>
  );
}

const CONGRATULATIONS_MESSAGES_DOWNLOADED = 'congratulations_messages_downloaded';
const CONGRATULATIONS_MESSAGE_DOWNLOADED = 'congratulations_message_downloaded';
const CONGRATULATIONS_MESSAGE_PENDING = 'congratulations_message_pending';
const CONGRATULATIONS_MESSAGE_SENDING = 'congratulations_message_sending';
const CONGRATULATIONS_MESSAGE_FAILED = 'congratulations_message_failed';
const CONGRATULATIONS_MESSAGE_REMOVED = 'congratulations_message_removed';

export {
  CONGRATULATIONS_MESSAGE_DOWNLOADED,
  CONGRATULATIONS_MESSAGE_FAILED,
  CONGRATULATIONS_MESSAGE_PENDING,
  CONGRATULATIONS_MESSAGE_REMOVED,
  CONGRATULATIONS_MESSAGE_SENDING,
  CONGRATULATIONS_MESSAGES_DOWNLOADED,
};

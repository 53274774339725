import React from 'react';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '../components/Menu/Menu';
import { useUserAuth } from '../contexts/UserAuthContext.js';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 40,
  },
  containerStyle: {
    width: '100%',
    maxWidth: '1200px',
    textAlign: 'justify',
    wordWrap: 'break-word',
  },
  withMenu: {
    marginLeft: '260px',
  },
});

/* eslint-disable max-len */
export default function PrivacyPage() {
  const classes = useStyles();
  const { token } = useUserAuth();
  return (
    <Container maxWidth={false} className={classes.root}>
      {token !== null
        ? <Menu />
        : ''}
      <Container
        maxWidth={false}
        className={`${classes.containerStyle} ${token ? classes.withMenu : ''}`}
      >
        <h1 id="politique-de-confidentialit-d-arth-e">Politique de confidentialité d&#39;ARTH-e</h1>
        <p>Version n° 2 en vigueur au 01 juin 2024.</p>
        <h2 id="information-sur-la-protection-de-vos-donn-es-personnelles">Information sur la Protection de vos données personnelles</h2>
        <p>
          En tant que responsable de traitement, le CHU de CLERMONT-FERRAND, 58 rue Montalembert 63000 CLERMONT-FERRAND qui est propriétaire de l’Application et initiateur du projet ARTH-e (« nous », « CHU ») est susceptible de traiter les données à caractère personnel des Utilisateur(s) collectées
          <span style={{ fontStyle: 'italic' }}> via </span>
          l’Application tels que ces termes sont définis dans les Conditions Générales Applicables de l&#39;application ARTH-e.
        </p>
        <p>Cette Politique de confidentialité (la « Politique ») vous informe de la manière dont nous traitons vos données personnelles, conformément au Règlement Général sur la Protection des Données n° 2016/679 de l’Union européenne (« RGPD »).</p>
        <h2 id="objet">Objet</h2>
        <p>La présente politique de confidentialité a vocation à fournir à l’Utilisateur des informations sur la façon dont ARTH-e : </p>
        <ul>
          <li>collecte, stocke et utilise ses Données Personnelles</li>
          <li>utilise des Cookies lors de l&#39;utilisation de l’Application.</li>
        </ul>
        <h2 id="d-finitions">Définitions</h2>
        <p>Dans la présente politique de confidentialité, les termes commençant par une majuscule ont la signification suivante et peuvent être utilisés indifféremment au singulier comme au pluriel :</p>
        <ul>
          <li>
            <p>« Application » désigne l’application mobile exploitée par le CHU sous l&#39;appelation « ARTH-e », à laquelle l’Utilisateur peut accéder après authentification forte, permettant la collecte et la gestion des Informations personnelles.</p>
          </li>
          <li>
            <p>« CGU » : conditions générales d’utilisation de l’Application.</p>
          </li>
          <li>
            <p>« Compte Utilisateur » désigne le compte individuel que l’Utilisateur a la faculté de créer, et à partir duquel l’Utilisateur peut accéder aux Services offerts par l’Application, conformément aux CGU.</p>
          </li>
          <li>
            <p>« Cookie » désigne un petit fichier d’informations que l’Application peut déposer et/ou lire dans un espace dédié du disque dur du terminal de l’Utilisateur quel que soit son type (tablette numérique, smartphone) à l’occasion de l’utilisation d’une Application.</p>
          </li>
          <li>
            <p>« Données Personnelles » désignent conformément à l’article 4 du RGPD et l&#39;article 4 de la loi n° 78-17 du 6 janvier 1978, toutes les informations à caractère personnel concernant un personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres.</p>
          </li>
          <li>
            <p>« Réglementation applicable à la protection des données personnelles » désigne toute réglementation applicable à la protection des données personnelles et en particulier le Règlement (UE) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 dit « Règlement Général sur la Protection des Données » et la Loi n°78-17 du 6 janvier 1978 modifiée dite « Loi Informatique et Libertés ».</p>
          </li>
          <li>
            <p>« Responsables de traitement » : le CHU de Clermont-Ferrand, propriétaire de l’Application et initiateur du projet ARTH-e et son sous-traitant, la société OPENIUM.</p>
          </li>
          <li>
            <p>« Utilisateur » désigne toute personne utilisant l’Application et ayant conclu à cet effet les présentes CGU.</p>
          </li>
        </ul>
        <p>
          Pour plus d’information sur le vocabulaire utilisé, l’Utilisateur peut consulter les CGU disponibles en cliquant sur le lien suivant :
          <a href="https://arth-e.openium.fr/cgu">https://arth-e.openium.fr/cgu</a>
          .

        </p>
        <h2 id="politique-relative-aux-donn-es-personnelles">Politique relative aux Données Personnelles</h2>
        <h3 id="identit-du-responsable-de-traitement">Identité du responsable de traitement</h3>
        <p>Les Données Personnelles sont collectées par :</p>
        <ul>
          <li>
            <p>le CHU de CLERMONT-FERRAND, Projet ARTH-e, Etablissement de santé situé 58 rue Montalembert 63000 CLERMONT-FERRAND, agissant en qualité de responsable de traitement au sens de l’article 24 du RGPD.</p>
          </li>
          <li>
            <p>OPENIUM, société par actions simplifiée au capital de 67 350 Euros dont le siège social est situé au 15 rue Jean Claret 63000 Clermont-Ferrand et immatriculée au Registre du Commerce et des Sociétés Clermont Ferrand, sous le numéro 499 284 859, en qualité d&#39;éditeur de l’Application.</p>
          </li>
        </ul>
        <h3 id="cat-gories-de-donn-es-personnelles-collect-es">Catégories de Données Personnelles collectées</h3>
        <p>Le CHU collecte et traite des Données Personnelles uniquement lorsqu’elles sont strictement nécessaires à la mise en œuvre des finalités décrites ci-dessous.</p>
        <p>Les Données Personnelles sont pour la plupart collectées directement auprès de l’Utilisateur, lorsqu’il utilise l’Application, par exemple lorsqu’il crée son Compte utilisateur ou contacte le CHU, etc.</p>
        <p>Les Données Personnelles que le CHU peut être amenée à collecter sont :</p>
        <ul>
          <li>
            <p>Données personnelles que vous fournissez directement : </p>
            <ul>
              <li>Adresse email si vous souhaitez créer un compte pour accéder à toutes les fonctionnalités de l’application.</li>
              <li>L’adresse IP, le modèle de l’appareil, le navigateur et la langue de l’appareil dont vous faites usage pour vous connecter eux services ; une copie de la correspondance (y compris par email) que vous êtes susceptible de nous adresser.</li>
            </ul>
          </li>
          <li>
            <p>Données collectées automatiquement, dans le cadre de l’étude ARTH-e 3 : «Efficacité de l’application ARTH-e sur l’adhésion aux exercices physiques des patients souffrant de gonarthrose», les données suivantes sont collectées par Openium et transmises anonymement au CHU de Clermont-Ferrand : </p>
            <ul>
              <li>Fréquence d’ouverture de l’application par semaine,</li>
              <li>Fréquence d’ouverture de l’application avec réalisation d’une session d’exercice par semaine,</li>
              <li>Temps passé sur l’application à chaque ouverture,</li>
              <li>Nombre de session(s) réalisée(s) sur un niveau avant de passer au niveau supérieur,</li>
              <li>Notation de chaque session d’exercice (sur 5 étoiles),</li>
              <li>Taux de réponses positives aux questionnaires vrai/faux.</li>
              <li>Niveau de douleur moyen sur 6 mois (relevé sur l’application au maximum 2 fois par semaine).</li>
            </ul>
          </li>
        </ul>
        <h3 id="finalit-s-du-traitement-de-donn-es-personnelles">Finalités du traitement de Données Personnelles</h3>
        <p>Les données personnelles communiquées par l’Utilisateur sont nécessaires pour son accès à l’Application et pour son utilisation ainsi que pour répondre à ses demandes et assurer le support et la maintenance de la Plateforme. </p>
        <p>Ces informations sont strictement confidentielles et destinées exclusivement au Responsable de traitement.</p>
        <h3 id="destinataires-de-donn-es-personnelles">Destinataires de Données Personnelles</h3>
        <p>Le Responsable de traitement s’engage à protéger les données personnelles de l’Utilisateur, enregistrées lors de son accès et/ou utilisation de l&#39;Application, conformément à la Réglementation applicable à la protection des données personnelles.</p>
        <p>Le Responsable de traitement s’engage à ne pas divulguer, céder, louer ou transmettre les données personnelles de l’Utilisateur à des tiers.</p>
        <p>Conformément à la Réglementation applicable à la protection des données personnelles, le Responsable de traitement s’engage à prendre toute précaution utile, au regard de la nature des données personnelles de l’Utilisateur et des risques présentés par le traitement, pour en préserver la sécurité et la confidentialité et, notamment, empêcher la destruction, la perte, l’altération, la divulgation ou l’accès non autorisé à ces données.</p>
        <h3 id="h-bergement-des-donn-es-personnelles">Hébergement des Données Personnelles</h3>
        <p>
          L’Application est hébergée sur un serveur fourni par la société Openium, 15 rue Jean Claret - 63000 Clermont-Ferrand – Web :
          <a href="https://www.openium.fr">https://www.openium.fr</a>
          , S.A.S. au capital de 67 350 € - RCS Clermont-Ferrand 499 284 859 - APE62.01Z  - TVA Intracommunautaire Numéro TVA: FR 86 499 284 859.
        </p>
        <p>
          L’hébergeur agit en tant que prestataire désigné par le Responsable de traitement pour le déploiement de l’Application (société OPENIUM) au sens de la Réglementation applicable à la protection des données personnelles, sur instructions du Responsable de traitement et dans les conditions contractuelles signées entre la société Openium et le Responsable de traitement qui ne peuvent déroger au présent article.
        </p>
        <h3 id="dur-e-de-conservation-des-donn-es-personnelles">Durée de conservation des Données Personnelles</h3>
        <p>
          Les données personnelles de l’Utilisateur seront conservées pendant la durée d’utilisation de l’Application par l’Utilisateur et seront archivées à la clôture de son compte pendant la durée légale nécessaire au respect des obligations légales du Responsable de traitement ou aux fins de preuve pour la constatation, l’exercice ou la défense d’un droit en justice.

        </p>
        <h3 id="droits-de-l-utilisateur">Droits de l’Utilisateur</h3>
        <p>Conformément à la Réglementation applicable à la protection des données personnelles (art. 34 de la loi &quot;Informatique et Libertés&quot;), l’Utilisateur dispose : </p>
        <ul>
          <li>
            <p>d&#39;un droit d’accès à ses Données Personnelles, à savoir le droit d’obtenir les informations collectées et la façon dont elles ont été traitées ;</p>
          </li>
          <li>
            <p>d&#39;un droit de rectification, à savoir le droit d’obtenir la rectification par le CHU des Données Personnelles inexactes et/ou de les compléter si elles sont incomplètes ;</p>
          </li>
          <li>
            <p>d&#39;un droit de limitation et de portabilité des données personnelles le concernant. L’Utilisateur reconnait et accepte qu’il ne dispose pas du droit à l’effacement de ses données, justifié par le respect des obligations légales mises à la charge du Responsable de traitement notamment en matière de règles d’accès et de traçabilité des accès aux Données de Santé par les Utilisateurs.</p>
          </li>
          <li>
            <p>
              d’un droit d’opposition à ce que ses données personnelles fassent l’objet d’un traitement, ainsi que du droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés s’il considère que le traitement opéré par le Responsable de traitement constitue une violation de ses données personnelles.
              La mise en œuvre par l’Utilisateur de son droit d’opposition à ce que ses données personnelles fassent l’objet d’un traitement entraîne ipso facto l’impossibilité pour l’Utilisateur d’utiliser et d’accéder à la Plateforme.
            </p>
          </li>
        </ul>
        <p>
          L’Utilisateur peut exercer ses droits à tout moment auprès du Responsable de traitement par courriel adressé à l’adresse suivante :
          <a href="mailto:dpd@chu-clermontferrand.fr"> dpd@chu-clermontferrand.fr </a>
          ou en contactant la Direction de la Recherche Clinique et de l’Innovation (DRCI), CHU de Clermont-Ferrand, Projet ARTH-e, 58 rue Montalembert, 63000 Clermont-Ferrand.
        </p>
        <h2 id="politique-relative-l-utilisation-des-cookies">Politique relative à l’utilisation des Cookies</h2>
        <h3 id="finalit-s-de-l-utilisation-des-cookies">Finalités de l’utilisation des Cookies</h3>
        <p>L&#39;application ARTH-e est susceptible d’utiliser des cookies afin d’ :</p>
        <ul>
          <li>assurer le fonctionnement de l’Application ;</li>
          <li>assurer la sécurité de l’Application.</li>
        </ul>
        <h3 id="acceptation-gestion-et-contr-le-des-cookies">Acceptation, gestion et contrôle des Cookies</h3>
        <p>Les cookies strictement nécessaires à la fourniture des services de ARTH-e sont exemptés de consentement.</p>
        <h2 id="modification">Modification</h2>
        <p>Le CHU met à jour la présente politique de confidentialité régulièrement, notamment afin de tenir compte du développement de l’Application et de ses services ou de se conformer à la réglementation en vigueur. </p>
        <p>Toute modification est publiée sur cette page avec une date de révision à jour.</p>
        <p>
          L’Utilisateur est invité à consulter cette page régulièrement pour toute mise à jour.

        </p>
        <h2 id="contacts">Contacts</h2>
        <p>
          En cas de questions, commentaires, réclamations ou demandes concernant cette politique de confidentialité, écrivez à
          <a href="mailto:arthe@chu-clermontferrand.fr"> arthe@chu-clermontferrand.fr</a>
          .
        </p>
      </Container>
    </Container>
  );
}

const SIGN_IN = '/';
const PRIVACY = '/confidentialite';
const CGU = '/cgu';
const LEGAL_NOTICES = '/mentions-legales';

const ADVICES = '/advices';
const ADVICE_ADD = `${ADVICES}/add`;
const ADVICE_EDIT = `${ADVICES}/:adviceId`;
const ENCOURAGEMENT = '/encouragements';
const STATISTICS_ENCOURAGEMENT_MESSAGE = `${ENCOURAGEMENT}/statistics-encouragement-messages`;
const STATISTICS_ENCOURAGEMENT_MESSAGE_ADD = `${STATISTICS_ENCOURAGEMENT_MESSAGE}/add`;
const STATISTICS_ENCOURAGEMENT_MESSAGE_EDIT = `${STATISTICS_ENCOURAGEMENT_MESSAGE}/:encouragementId`;
const CONGRATULATIONS_MESSAGE = `${ENCOURAGEMENT}/congratulations-messages`;
const CONGRATULATIONS_MESSAGE_ADD = `${CONGRATULATIONS_MESSAGE}/add`;
const CONGRATULATIONS_MESSAGE_EDIT = `${CONGRATULATIONS_MESSAGE}/:congratulationsMessageId`;
const EXERCISE_ENCOURAGEMENT_MESSAGE = `${ENCOURAGEMENT}/exercise-encouragement-messages`;
const EXERCISE_ENCOURAGEMENT_MESSAGE_ADD = `${EXERCISE_ENCOURAGEMENT_MESSAGE}/add`;
const EXERCISE_ENCOURAGEMENT_MESSAGE_EDIT = `${EXERCISE_ENCOURAGEMENT_MESSAGE}/:exerciseEncouragementMessageId`;
const EXERCISES = '/exercises';
const EXERCISE_ADD = `${EXERCISES}/add`;
const EXERCISE_EDIT = `${EXERCISES}/:exerciseId`;
const PROGRAMS = '/programs';
const PROGRAM_ADD = `${PROGRAMS}/add`;
const PROGRAM_EDIT = `${PROGRAMS}/:programId`;
const QUESTIONS = '/questions';
const QUESTION_ADD = `${QUESTIONS}/add`;
const QUESTION_EDIT = `${QUESTIONS}/:questionId`;

export {
  ADVICE_EDIT,
  ADVICE_ADD,
  ADVICES,
  SIGN_IN,
  ENCOURAGEMENT,
  STATISTICS_ENCOURAGEMENT_MESSAGE_ADD,
  STATISTICS_ENCOURAGEMENT_MESSAGE_EDIT,
  STATISTICS_ENCOURAGEMENT_MESSAGE,
  CONGRATULATIONS_MESSAGE,
  CONGRATULATIONS_MESSAGE_ADD,
  CONGRATULATIONS_MESSAGE_EDIT,
  EXERCISE_ENCOURAGEMENT_MESSAGE,
  EXERCISE_ENCOURAGEMENT_MESSAGE_ADD,
  EXERCISE_ENCOURAGEMENT_MESSAGE_EDIT,
  EXERCISE_ADD,
  EXERCISE_EDIT,
  EXERCISES,
  PROGRAM_ADD,
  PROGRAM_EDIT,
  PROGRAMS,
  QUESTION_ADD,
  QUESTION_EDIT,
  QUESTIONS,
  PRIVACY,
  CGU,
  LEGAL_NOTICES,
};

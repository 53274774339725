import React, {
  createContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

function MuiAlert(props) {
  return <Alert {...props} elevation={6} variant="filled" />;
}

const NotificationContext = createContext(
  {
    notification: {
      isOpened: false,
      severity: 'info',
      message: '',
    },
    setNotification: () => {
    },
    notifyError: () => {
    },
    notifyApiError: () => {
    },
    notifySuccess: () => {
    },
  },
);

const initialState = {
  isOpened: false,
  severity: 'info',
  message: '',
};

const defaultTimeoutDuration = 5000;

const NotificationProvider = ({ children }) => {
  const [displayMessage, setDisplayMessage] = useState('');
  const [dialogInterval, setDialogInterval] = useState(null);
  const [notification, setNotification] = useState(initialState);
  const [dialogTimeout, setDialogTimeout] = useState(defaultTimeoutDuration);

  const { message } = notification;

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setNotification({
      ...notification,
      message: '',
      isOpened: false,
    });
    setDialogTimeout(defaultTimeoutDuration);
    if (dialogInterval !== null) {
      clearInterval(dialogInterval);
    }
    setDialogInterval(null);
  };

  const notifySuccess = (newMessage, timeout) => {
    if (timeout !== undefined) {
      setDialogTimeout(timeout);
    }
    setNotification({
      isOpened: true,
      severity: 'success',
      message: newMessage,
    });
  };

  const notifyError = (error, timeout) => {
    if (timeout !== undefined) {
      setDialogTimeout(timeout);
    }
    setNotification({
      isOpened: true,
      severity: 'error',
      message: error,
    });
  };

  const notifyApiError = (error, timeout) => {
    if (timeout !== undefined) {
      setDialogTimeout(timeout);
    }
    let newMessage = '';
    if (error.response?.status === 401) {
      return;
    }
    if (error.response?.status === 400) {
      newMessage = error.response?.data?.message ?? error.message;
    } else if (error.response?.status === 403) {
      newMessage = 'Nous ne pouvez pas executer cette action';
    } else {
      newMessage = error.message;
    }
    setNotification({
      isOpened: true,
      severity: 'error',
      message: newMessage,
    });
  };

  useEffect(() => {
    if (message !== '') {
      if (dialogInterval !== null) {
        clearInterval(dialogInterval);
      }
      setDisplayMessage(message);
      const newInterval = setInterval(
        () => {
          if (notification.isOpened) {
            setNotification({
              ...notification,
              message: '',
              isOpened: false,
            });
            setDialogTimeout(defaultTimeoutDuration);
          }
        },
        dialogTimeout,
      );
      setDialogInterval(newInterval);
    }
  }, [message]);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
        notifyError,
        notifySuccess,
        notifyApiError,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={notification.isOpened}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div>
          <MuiAlert onClose={handleClose} severity={notification.severity}>
            {displayMessage}
          </MuiAlert>
        </div>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  NotificationContext,
  NotificationProvider,
};

import {
  EXERCISE_ENCOURAGEMENT_MESSAGE_SENDING,
  EXERCISE_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_REMOVED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_PENDING,
  EXERCISE_ENCOURAGEMENT_MESSAGE_FAILED,
  EXERCISE_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: null,
  sending: null,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXERCISE_ENCOURAGEMENT_MESSAGE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case EXERCISE_ENCOURAGEMENT_MESSAGES_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case EXERCISE_ENCOURAGEMENT_MESSAGE_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case EXERCISE_ENCOURAGEMENT_MESSAGE_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case EXERCISE_ENCOURAGEMENT_MESSAGE_DOWNLOADED: {
      const messageDownloaded = action.payload;
      let messages = [];
      let messageIndex = -1;
      if (state.content) {
        messages = [...state.content];
        messageIndex = messages.findIndex(
          (msg) => msg.id === messageDownloaded.id,
        );
      }
      if (messageIndex === -1) {
        messages.push(action.payload);
      } else {
        messages[messageIndex] = action.payload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: messages,
      };
    }
    case EXERCISE_ENCOURAGEMENT_MESSAGE_REMOVED: {
      const message = action.payload;
      let messages = [...state.content];
      // eslint-disable-next-line func-names
      const isMessage = (messageId) => function (element) {
        return element.id !== messageId;
      };
      messages = state.content.filter(isMessage(message.id));
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: messages,
      };
    }
    default:
      break;
  }
  return state;
}

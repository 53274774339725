import React from 'react';
import Container from '@mui/material/Container';
import { Paper, Typography } from '@mui/material';
import SignInForm from '../../../components/forms/SignInForm';
import logo from '../../../resources/images/logo.svg';
import useStyles from './SignInPage.style';

export default function SignInPage() {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <img src={logo} alt="Arth-e" className={classes.logo} />
        <Typography variant="h4" component="h1">
          Connexion
        </Typography>
        <SignInForm />
      </Paper>
    </Container>
  );
}

import {
  PROGRAM_REMOVED,
  PROGRAM_DOWNLOADED,
  PROGRAMS_UPDATED,
  PROGRAM_FAILED,
  PROGRAM_PENDING,
  PROGRAMS_DOWNLOADED,
  PROGRAM_SENDING,
  PROGRAM_CHANGE_ORDER,
} from './types';
import {
  apiChangeProgramOrder,
  apiDeleteProgram,
  apiGetPrograms,
  apiPostProgram,
  apiPutProgram,
} from '../../api/ProgramApi';

export const programsDownloadedAction = (program) => (
  {
    type: PROGRAMS_DOWNLOADED,
    payload: program,
  }
);
export const programsUpdatedAction = (programs) => (
  {
    type: PROGRAMS_UPDATED,
    payload: programs,
  }
);
export const programDownloadedAction = (program) => (
  {
    type: PROGRAM_DOWNLOADED,
    payload: program,
  }
);
export const programPendingAction = () => (
  {
    type: PROGRAM_PENDING,
  }
);
export const programSendingAction = () => (
  {
    type: PROGRAM_SENDING,
  }
);
export const programRemovedAction = (program) => (
  {
    type: PROGRAM_REMOVED,
    payload: program,
  }
);
export const programFailedAction = (error) => (
  {
    type: PROGRAM_FAILED,
    payload: error,
  }
);

export const programChangeOrderAction = (programId, newIndex) => (
  {
    type: PROGRAM_CHANGE_ORDER,
    payload: {
      programId,
      newIndex,
    },
  }
);

export function getPrograms(token) {
  return (dispatch) => {
    dispatch(programPendingAction());
    return apiGetPrograms(token)
      .then((result) => {
        dispatch(programsDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(programFailedAction(error));
        throw error;
      });
  };
}

export function postProgram(token, data) {
  return (dispatch) => {
    dispatch(programSendingAction());
    return apiPostProgram(token, data)
      .then((result) => {
        dispatch(programDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(programFailedAction(error));
        throw error;
      });
  };
}

export function putProgram(token, id, data) {
  return (dispatch) => {
    dispatch(programSendingAction());
    return apiPutProgram(token, id, data)
      .then((result) => {
        dispatch(programDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(programFailedAction(error));
        throw error;
      });
  };
}

export function deleteProgram(token, program) {
  return (dispatch) => {
    dispatch(programSendingAction());

    return apiDeleteProgram(token, program.id)
      .then(() => {
        dispatch(programRemovedAction(program));
        return program;
      })
      .catch((error) => {
        dispatch(programFailedAction(error));
        throw error;
      });
  };
}

export function changeProgramOrder(token, programId, newOrder) {
  return (dispatch) => {
    dispatch(programChangeOrderAction(programId, newOrder));
    return apiChangeProgramOrder(token, programId, newOrder)
      .then((result) => {
        dispatch(programsUpdatedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(programFailedAction(error));
        throw error;
      });
  };
}

import React from 'react';
import PropTypes from 'prop-types';

const ChartBar = ({
  color,
  width,
  height,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="FontAwesome/Regular/chart-bar">
      <path
        id="Mask"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6544 18.9235C23.9666 18.9235 24.2221 19.179 24.2221 19.4912V20.6266C24.2221 20.9389 23.9666 21.1943 23.6544 21.1943H0.567706C0.255468 21.1943 0 20.9389 0 20.6266V3.59548C0 3.28324 0.255468 3.02777 0.567706 3.02777H1.70312C2.01535 3.02777 2.27082 3.28324 2.27082 3.59548V18.9235H23.6544ZM6.81247 16.085V12.6788C6.81247 12.3665 6.557 12.1111 6.24476 12.1111H5.10935C4.79711 12.1111 4.54164 12.3665 4.54164 12.6788V16.085C4.54164 16.3972 4.79711 16.6527 5.10935 16.6527H6.24476C6.557 16.6527 6.81247 16.3972 6.81247 16.085ZM11.3541 6.62324V16.085C11.3541 16.3972 11.0986 16.6527 10.7864 16.6527H9.65099C9.33876 16.6527 9.08329 16.3972 9.08329 16.085V6.62324C9.08329 6.311 9.33876 6.05553 9.65099 6.05553H10.7864C11.0986 6.05553 11.3541 6.311 11.3541 6.62324ZM15.8958 16.085V9.651C15.8958 9.33876 15.6403 9.0833 15.3281 9.0833H14.1926C13.8804 9.0833 13.6249 9.33876 13.6249 9.651V16.085C13.6249 16.3972 13.8804 16.6527 14.1926 16.6527H15.3281C15.6403 16.6527 15.8958 16.3972 15.8958 16.085ZM20.4374 5.10936V16.085C20.4374 16.3972 20.1819 16.6527 19.8697 16.6527H18.7343C18.422 16.6527 18.1666 16.3972 18.1666 16.085V5.10936C18.1666 4.79712 18.422 4.54165 18.7343 4.54165H19.8697C20.1819 4.54165 20.4374 4.79712 20.4374 5.10936Z"
        fill={color}
      />
    </g>
  </svg>
);
ChartBar.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
ChartBar.defaultProps = {
  color: 'currentColor',
  width: 25,
  height: 25,
};

export default ChartBar;

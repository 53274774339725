import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Container from '@mui/material/Container';
import ExerciseForm from '../../components/forms/ExerciseForm';
import { getExercises } from '../../store/exercise/action';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import { getNecessaryEquipments } from '../../store/necessaryEquipment/action';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

export default function EditExercisePage() {
  const { exerciseId } = useParams();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.exercise);
  const neState = useSelector((state) => state.necessaryEquipment);
  const dispatch = useDispatch();
  const [exercise, setExercise] = useState(null);
  const { token } = useUserAuth();

  useEffect(() => {
    if (content !== null) {
      setExercise(content.find(
        (ex) => ex.id === exerciseId,
      ));
    }
  }, [content]);

  if (neState.content === null && !neState.pending && neState.error === null && token !== null) {
    dispatch(getNecessaryEquipments(token));
  }

  if (!content && !pending && !error && token !== null) {
    dispatch(getExercises(token));
  }

  return (
    <Container maxWidth={false}>
      <PageTitle title="Modifier un exercice" />
      {(
        pending || content === null || neState.pending
      ) && <PageCircularProgress />}
      {content !== null && neState.content !== null
        && (
          <ExerciseForm
            exercise={exercise}
            necessaryEquipments={neState.content ?? []}
          />
        )}
    </Container>
  );
}

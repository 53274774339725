const STATISTICS_ENCOURAGEMENT_MESSAGES_DOWNLOADED = 'statistics_encouragement_messages_downloaded';
const STATISTICS_ENCOURAGEMENT_MESSAGE_DOWNLOADED = 'statistics_encouragement_message_downloaded';
const STATISTICS_ENCOURAGEMENT_MESSAGE_PENDING = 'statistics_encouragement_message_pending';
const STATISTICS_ENCOURAGEMENT_MESSAGE_SENDING = 'statistics_encouragement_message_sending';
const STATISTICS_ENCOURAGEMENT_MESSAGE_FAILED = 'statistics_encouragement_message_failed';
const STATISTICS_ENCOURAGEMENT_MESSAGE_REMOVED = 'statistics_encouragement_message_removed';

export {
  STATISTICS_ENCOURAGEMENT_MESSAGE_DOWNLOADED,
  STATISTICS_ENCOURAGEMENT_MESSAGE_FAILED,
  STATISTICS_ENCOURAGEMENT_MESSAGE_PENDING,
  STATISTICS_ENCOURAGEMENT_MESSAGE_REMOVED,
  STATISTICS_ENCOURAGEMENT_MESSAGE_SENDING,
  STATISTICS_ENCOURAGEMENT_MESSAGES_DOWNLOADED,
};

import React, { useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { DragDropContext } from 'react-beautiful-dnd';
import { changeProgramOrder, deleteProgram, getPrograms } from '../../store/program/action';
import { PROGRAM_ADD } from '../../constants/routes';
import ValidationDialog from '../../components/ValidationDialog/ValidationDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import StrictModeDroppable from './StrictModeDroppable';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';

export default function ProgramPage() {
  const {
    pending,
    content,
    error,
    sending,
  } = useSelector((state) => state.program);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [programToDelete, setProgramToDelete] = useState(null);
  const tableRef = useRef();
  const { token } = useUserAuth();

  if (!content && !pending && !error && token !== null) {
    dispatch(getPrograms(token));
  }

  const handleAdd = () => {
    navigate(PROGRAM_ADD);
  };

  const handleToggleDialog = (program) => {
    setProgramToDelete(program);
    setOpenDialog(!openDialog);
  };

  const handleDelete = (program) => {
    dispatch(deleteProgram(token, program))
      .then(handleToggleDialog);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    if (result.destination.index !== result.source.index && token !== null) {
      dispatch(changeProgramOrder(
        token,
        result.draggableId,
        result.destination.index,
      ));
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Container maxWidth={false}>
      <ValidationDialog
        handleClose={handleToggleDialog}
        openDialog={openDialog}
        handleAction={() => handleDelete(programToDelete)}
        title="Êtes-vous sûr de vouloir supprimer ce programme ?"
        text="Vous êtes sur le point de supprimer un programme. Attention, cette action est irréversible."
        sending={sending}
      />
      <PageTitle
        title="Programmes"
        onClick={handleAdd}
        actionTitle="Ajouter un programme"
      />
      {pending && <PageCircularProgress />}
      {(
        content !== null && !pending
      ) && (
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'fixed' }} ref={tableRef} id="programTable">
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Niveau</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <StrictModeDroppable
                tableRef={tableRef}
                content={content}
                handleToggleDialog={handleToggleDialog}
                sending={sending}
              />
            </Table>
          </TableContainer>
        </DragDropContext>
      )}
    </Container>
  );
}

import axios from 'axios';
import API_ROOT from '../constants/api';
import { getHeaders } from '../services/ApiServices';

const ROUTE = '/admin/programs';

export const apiGetPrograms = (token) => axios
  .get(`${API_ROOT}${ROUTE}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiPostProgram = (token, data) => axios
  .post(`${API_ROOT}${ROUTE}`, data, {
    headers: {
      ...getHeaders(token),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  .then((response) => response.data);

export const apiPutProgram = (token, id, data) => axios
  .post(`${API_ROOT}${ROUTE}/${id}`, data, {
    headers: {
      ...getHeaders(token),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  .then((response) => response.data);

export const apiDeleteProgram = (token, id) => axios
  .delete(`${API_ROOT}${ROUTE}/${id}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiChangeProgramOrder = (token, id, newOrder) => axios
  .put(`${API_ROOT}${ROUTE}/${id}/order`, { new_order: newOrder }, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

const QUESTIONS_DOWNLOADED = 'questions_downloaded';
const QUESTION_DOWNLOADED = 'question_downloaded';
const QUESTION_PENDING = 'question_pending';
const QUESTION_SENDING = 'question_sending';
const QUESTION_FAILED = 'question_failed';
const QUESTION_REMOVED = 'question_removed';

export {
  QUESTION_DOWNLOADED,
  QUESTION_FAILED,
  QUESTION_PENDING,
  QUESTION_REMOVED,
  QUESTION_SENDING,
  QUESTIONS_DOWNLOADED,
};

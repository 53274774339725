import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignInPage from './user/SignInPage/SignInPage';
import HomePage from './HomePage';
import PrivacyPage from './PrivacyPage';
import CGUPage from './CGUPage';
import LegalNoticesPage from './LegalNoticesPage';
import AdvicePage from './Advice/AdvicePage';
import {
  ADVICE_ADD,
  ADVICE_EDIT,
  ADVICES,
  STATISTICS_ENCOURAGEMENT_MESSAGE_ADD,
  STATISTICS_ENCOURAGEMENT_MESSAGE_EDIT,
  STATISTICS_ENCOURAGEMENT_MESSAGE,
  EXERCISE_ADD,
  EXERCISE_EDIT,
  EXERCISES,
  PROGRAM_ADD,
  PROGRAM_EDIT,
  PROGRAMS,
  QUESTION_ADD,
  QUESTION_EDIT,
  QUESTIONS,
  SIGN_IN,
  PRIVACY,
  CGU,
  LEGAL_NOTICES,
  ENCOURAGEMENT,
  CONGRATULATIONS_MESSAGE,
  EXERCISE_ENCOURAGEMENT_MESSAGE,
  EXERCISE_ENCOURAGEMENT_MESSAGE_ADD,
  EXERCISE_ENCOURAGEMENT_MESSAGE_EDIT, CONGRATULATIONS_MESSAGE_ADD, CONGRATULATIONS_MESSAGE_EDIT,
} from '../constants/routes';
import AddAdvicePage from './Advice/AddAdvicePage';
import EditAdvicePage from './Advice/EditAdvicePage';
import StatisticsEncouragementMessagesPage
  from './Encouragement/StatisticsEncouragementMessage/StatisticsEncouragementMessagesPage';
import AddStatisticsEncouragementMessagePage
  from './Encouragement/StatisticsEncouragementMessage/AddStatisticsEncouragementMessagePage';
import EditStatisticsEncouragementMessagePage
  from './Encouragement/StatisticsEncouragementMessage/EditStatisticsEncouragementMessagePage';
import EncouragementPage from './Encouragement/EncouragementPage';
import ExercisePage from './Exercise/ExercisePage';
import AddExercisePage from './Exercise/AddExercisePage';
import EditExercisePage from './Exercise/EditExercisePage';
import ProgramPage from './Program/ProgramPage';
import AddProgramPage from './Program/AddProgramPage';
import EditProgramPage from './Program/EditProgramPage';
import QuestionPage from './Question/QuestionPage';
import AddQuestionPage from './Question/AddQuestionPage';
import EditQuestionPage from './Question/EditQuestionPage';
import CongratulationsMessagesPage
  from './Encouragement/CongratulationsMessage/CongratulationsMessagesPage';
import AddCongratulationsMessagePage
  from './Encouragement/CongratulationsMessage/AddCongratulationsMessagePage';
import ExerciseEncouragementMessagesPage
  from './Encouragement/ExerciseEncouragementMessage/ExerciseEncouragementMessagesPage';
import AddExerciseEncouragementMessagePage
  from './Encouragement/ExerciseEncouragementMessage/AddExerciseEncouragementMessagePage';
import EditExerciseEncouragementMessagePage
  from './Encouragement/ExerciseEncouragementMessage/EditExerciseEncouragementMessagePage';
import EditCongratulationsMessagePage
  from './Encouragement/CongratulationsMessage/EditCongratulationsMessagePage';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';

// eslint-disable-next-line react/prop-types
export default function App() {
  return (
    <>
      <Routes>
        <Route
          exact
          path={PRIVACY}
          element={<PrivacyPage />}
        />
        <Route
          exact
          path={CGU}
          element={<CGUPage />}
        />
        <Route
          exact
          path={LEGAL_NOTICES}
          element={<LegalNoticesPage />}
        />
        <Route
          exact
          path={SIGN_IN}
          element={<SignInPage />}
        />
        <Route
          path="/"
          element={(
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          )}
        >
          <Route
            exact
            path={ADVICES}
            element={(
              <ProtectedRoute>
                <AdvicePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={ADVICE_ADD}
            element={(
              <ProtectedRoute>
                <AddAdvicePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={ADVICE_EDIT}
            element={(
              <ProtectedRoute>
                <EditAdvicePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={ENCOURAGEMENT}
            element={(
              <ProtectedRoute>
                <EncouragementPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={CONGRATULATIONS_MESSAGE}
            element={(
              <ProtectedRoute>
                <CongratulationsMessagesPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={CONGRATULATIONS_MESSAGE_ADD}
            element={(
              <ProtectedRoute>
                <AddCongratulationsMessagePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={CONGRATULATIONS_MESSAGE_EDIT}
            element={(
              <ProtectedRoute>
                <EditCongratulationsMessagePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={EXERCISE_ENCOURAGEMENT_MESSAGE}
            element={(
              <ProtectedRoute>
                <ExerciseEncouragementMessagesPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={EXERCISE_ENCOURAGEMENT_MESSAGE_ADD}
            element={(
              <ProtectedRoute>
                <AddExerciseEncouragementMessagePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={EXERCISE_ENCOURAGEMENT_MESSAGE_EDIT}
            element={(
              <ProtectedRoute>
                <EditExerciseEncouragementMessagePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={STATISTICS_ENCOURAGEMENT_MESSAGE}
            element={(
              <ProtectedRoute>
                <StatisticsEncouragementMessagesPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={STATISTICS_ENCOURAGEMENT_MESSAGE_ADD}
            element={(
              <ProtectedRoute>
                <AddStatisticsEncouragementMessagePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={STATISTICS_ENCOURAGEMENT_MESSAGE_EDIT}
            element={(
              <ProtectedRoute>
                <EditStatisticsEncouragementMessagePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={EXERCISES}
            element={(
              <ProtectedRoute>
                <ExercisePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={EXERCISE_ADD}
            element={(
              <ProtectedRoute>
                <AddExercisePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={EXERCISE_EDIT}
            element={(
              <ProtectedRoute>
                <EditExercisePage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={PROGRAMS}
            element={(
              <ProtectedRoute>
                <ProgramPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={PROGRAM_ADD}
            element={(
              <ProtectedRoute>
                <AddProgramPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={PROGRAM_EDIT}
            element={(
              <ProtectedRoute>
                <EditProgramPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={QUESTIONS}
            element={(
              <ProtectedRoute>
                <QuestionPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={QUESTION_ADD}
            element={(
              <ProtectedRoute>
                <AddQuestionPage />
              </ProtectedRoute>
            )}
          />
          <Route
            exact
            path={QUESTION_EDIT}
            element={(
              <ProtectedRoute>
                <EditQuestionPage />
              </ProtectedRoute>
            )}
          />
        </Route>
      </Routes>
    </>
  );
}

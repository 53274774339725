import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ButtonGroup,
  CardContent,
  InputAdornment,
  Paper,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dropzone from 'react-dropzone';
import CardMedia from '@mui/material/CardMedia';
import { EXERCISE_ADD, PROGRAMS } from '../../constants/routes';
import { postProgram, putProgram } from '../../store/program/action';
import { NotificationContext } from '../../contexts/NotificationContext';
import ProgramExerciceTableForm from './ProgramExerciceTableForm';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import PageTitle from '../PageTitle/PageTitle';
import pictureIcon from '../../resources/icons/picture-icon.svg';
import {
  artheDarkBlue,
  artheNuance200,
  artheNuance300,
  artheWhite,
} from '../../constants/colors';
import { ProgramExerciseProvider } from '../../contexts/ProgramExerciseContext';
import lock from '../../resources/icons/lock-icon.svg';
import save from '../../resources/icons/save-icon.svg';

const useStyles = makeStyles((theme) => (
  {
    form: {
      maxWidth: '1400px',
    },
    dropzoneGridItem: {
      display: 'flex',
      alignItems: 'center',
      width: '249px',
      height: '249px',
      backgroundImage: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'12\' ry=\'12\' stroke=\'black\' stroke-width=\'2\' stroke-dasharray=\'6%2c 14\' stroke-dashoffset=\'5\' stroke-linecap=\'square\'/%3e%3c/svg%3e")',
      borderRadius: '12px',
      overflow: 'hidden',
    },
    cardcontent: {
      textAlign: 'center',
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    imgpic: {
      objectFit: 'contain',
    },
    addImageText: {
      fontWeight: 700,
      width: '70%',
      margin: '0 auto',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '16px',
      padding: '30px 40px 40px 40px',
      marginTop: '21px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    input: {
      borderRadius: '8px',
      padding: '14px 16px',
      boxShadow: '0px 5px 8px 0px #28778714',
    },
    buttonGroup: {
      marginTop: '10px',
      width: '100%',
    },
    commonGroupButton: {
      margin: 0,
      padding: '10px 10px 12px',
      flex: 1,
      textTransform: 'initial',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    selectedButton: {
      backgroundColor: artheDarkBlue,
      color: artheWhite,
      '&:hover': {
        backgroundColor: artheDarkBlue,
        color: artheWhite,
      },
    },
  }
));

export default function ProgramForm(props) {
  const {
    program,
    pageTitle,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { content } = useSelector((state) => state.exercise);
  const { sending } = useSelector((state) => state.program);
  const {
    notifyApiError,
    notifySuccess,
  } = useContext(NotificationContext);
  const { token } = useUserAuth();

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [level, setLevel] = useState(1);
  const [exercisesId, setExercisesId] = useState([]);
  const [picture, setPicture] = useState(null);
  const [picturePath, setPicturePath] = useState(null);
  const [deletePicture, setDeletePicture] = useState(false);
  const [exerciseCardioList, setExerciseCardioList] = useState([]);
  const [exerciseArtiList, setExerciseArtiList] = useState([]);
  const [exerciseMuscuList, setExerciseMuscuList] = useState([]);
  const [exerciseProprioList, setExerciseProprioList] = useState([]);
  const [exerciseStretchList, setExerciseStretchList] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState(program && program.level !== 0
    ? program.level
    : 1);

  const setExerciseLists = (maxLevel) => {
    setExerciseCardioList(
      content.filter((exercise) => exercise.level <= maxLevel && exercise.type === 'CARDIO'),
    );
    setExerciseArtiList(
      content.filter((exercise) => exercise.level <= maxLevel && exercise.type === 'ARTI'),
    );
    setExerciseMuscuList(
      content.filter((exercise) => exercise.level <= maxLevel && exercise.type === 'MUSCU'),
    );
    setExerciseProprioList(
      content.filter((exercise) => exercise.level <= maxLevel && exercise.type === 'PROPRIO'),
    );
    setExerciseStretchList(
      content.filter((exercise) => exercise.level <= maxLevel && exercise.type === 'STRETCH'),
    );
  };
  useEffect(() => {
    if (program) {
      setId(program.id);
      setTitle(program.title);
      setLevel(program.level === 0
        ? 1
        : program.level);
      setExercisesId(
        program.exercise_programs.map((exerciseProgram) => exerciseProgram.exercise.id),
      );
      setPicture(program.picture);
      setPicturePath(program.image_url);
      setExerciseLists(program.level);
    }
  }, [program]);

  useEffect(() => {
    setExerciseLists(level);
  }, [level]);

  const isEditing = id !== '';

  const supportedDropFiles = [
    'image/bmp',
    'image/x-ms-bmp',
    'image/png',
    'image/x-icon',
    'image/vnd.microsoft.icon',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
  ].toString();

  const isSelected = (exerciseRow) => !!exercisesId.some(
    (e) => e === exerciseRow.id,
  );

  const handleClick = (exerciseRow) => {
    if (exercisesId.some((e) => e === exerciseRow.id)) {
      setExercisesId(exercisesId.filter((e) => e !== exerciseRow.id));
    } else {
      setExercisesId([...exercisesId, exerciseRow.id]);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (token !== null) {
      const data = {
        id,
        title,
        level,
        exercises_id: exercisesId,
        delete_picture: deletePicture,
      };
      const formData = new FormData();
      formData.append('content', JSON.stringify(data));
      if (picture) {
        formData.append('image', picture);
      }
      if (isEditing) {
        dispatch(putProgram(token, id, formData))
          .then(() => navigate(PROGRAMS))
          .then(() => notifySuccess('Le programme a bien été sauvegardé'))
          .catch((error) => notifyApiError(error));
      } else {
        dispatch(postProgram(token, formData))
          .then(() => navigate(PROGRAMS))
          .then(() => notifySuccess('Le programme a bien été créé'))
          .catch((error) => notifyApiError(error));
      }
    }
  };
  const handleMediaDrop = (accepted, rejected) => {
    if (!rejected.length) {
      setPicturePath(URL.createObjectURL(accepted[0]));
      setPicture(accepted[0]);
    }
  };

  const handleDeleteMedia = () => {
    setPicturePath('');
    setPicture(null);
    setDeletePicture(true);
  };

  const selectedExercicesCount = exercisesId
    ? exercisesId.length
    : 0;

  if (content !== null && content.length === 0) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        style={{ marginTop: '20px' }}
      >
        <Grid item>
          <Typography variant="body1">
            Vous devez avoir créé au moins un exercice pour pouvoir créer un programme
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => navigate(EXERCISE_ADD)}
          >
            Ajouter un exercice
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Grid container direction="column" gap="0.5rem">
        <PageTitle
          title={pageTitle}
          actionTitle={isEditing
            ? 'Sauvegarder'
            : 'Valider'}
          sending={sending}
          icon={save}
          onClick={onSubmit}
          disabled={sending || selectedExercicesCount !== 9}
        />
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="subtitle1">Informations générales</Typography>
          <Grid container direction="row" gap="40px" wrap={'nowrap'}>
            <Grid container direction="column" style={{ width: '249px' }} gap="12px">
              <Grid item className={classes.dropzoneGridItem}>
                <Dropzone
                  onDrop={handleMediaDrop}
                  accept={supportedDropFiles}
                  disabled={picturePath !== null && picturePath !== ''}
                >
                  {({
                    getRootProps,
                    getInputProps,
                  }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <CardContent className={classes.cardcontent}>
                          {picturePath
                            ? (
                              <>
                                <CardMedia
                                  component="img"
                                  image={picturePath}
                                  title="Image"
                                  className={classes.imgpic}
                                />

                              </>
                            )
                            : (
                              <Grid item>
                                <img src={pictureIcon} alt="icon to add a personnal file" />
                                <Typography
                                  color="primary"
                                  className={classes.addImageText}
                                >
                                  Glissez et déposez votre image ici.
                                </Typography>
                              </Grid>
                            )}
                        </CardContent>

                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              {picturePath
                ? (
                  <Grid item>
                    <Button
                      size="small"
                      color="primary"
                      onClick={handleDeleteMedia}
                      variant="outlined"
                      style={{ minWidth: '0' }}
                    >
                      {'Supprimer'}
                    </Button>
                  </Grid>
                )
                : (
                  <Grid item>
                    <Typography variant="body2">Attention, image JPG ou PNG, maximum de 2 MO.</Typography>
                  </Grid>
                )}
            </Grid>
            <Grid item>
              <Grid container gap="1.8rem" wrap="nowrap" direction="column">
                <Grid item>
                  <Typography
                    color={artheNuance200}
                    style={{ fontWeight: 700 }}
                  >
                    Titre du programme*
                  </Typography>
                  <TextField
                    id="title"
                    type="string"
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={lock} alt="lock icon for program's title" />
                        </InputAdornment>
                      ),
                      style: {
                        color: artheNuance300,
                      },
                    }}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setTitleError('');
                    }}
                    value={title}
                    className={classes.input}
                    multiline
                    error={titleError !== ''}
                    helperText={titleError}
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography
                      color={artheNuance200}
                      style={{ fontWeight: 700 }}
                    >
                      Niveau du programme*
                    </Typography>
                    <ButtonGroup
                      variant="outlined"
                      color="secondary"
                      aria-label="outlined primary button group"
                      className={classes.buttonGroup}
                    >
                      <Button
                        className={`${classes.commonGroupButton} 
                          ${selectedBtn === 1
                          ? classes.selectedButton
                          : ''}
                        `}
                        onClick={() => {
                          setSelectedBtn(1);
                          setLevel(1);
                        }}
                      >
                        Niveau 1
                      </Button>
                      <Button
                        className={`${classes.commonGroupButton} 
                          ${selectedBtn === 2
                          ? classes.selectedButton
                          : ''}
                        `}
                        onClick={() => {
                          setSelectedBtn(2);
                          setLevel(2);
                        }}
                      >
                        Niveau 2
                      </Button>
                      <Button
                        className={`${classes.commonGroupButton} 
                          ${selectedBtn === 3
                          ? classes.selectedButton
                          : ''}
                        `}
                        onClick={() => {
                          setSelectedBtn(3);
                          setLevel(3);
                        }}
                      >
                        Niveau 3
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper} elevation={0}>
          <Typography variant="subtitle1">Contenu du programme</Typography>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={2}
          >
            <ProgramExerciseProvider>
              <ProgramExerciceTableForm
                handleClick={handleClick}
                isSelected={isSelected}
                exerciseList={exerciseCardioList}
                numberOfNeededExercises={1}
              />
            </ProgramExerciseProvider>
            <ProgramExerciseProvider>
              <ProgramExerciceTableForm
                handleClick={handleClick}
                isSelected={isSelected}
                exerciseList={exerciseArtiList}
                numberOfNeededExercises={1}
              />
            </ProgramExerciseProvider>
            <ProgramExerciseProvider>
              <ProgramExerciceTableForm
                handleClick={handleClick}
                isSelected={isSelected}
                exerciseList={exerciseMuscuList}
                numberOfNeededExercises={3}
              />
            </ProgramExerciseProvider>
            <ProgramExerciseProvider>
              <ProgramExerciceTableForm
                handleClick={handleClick}
                isSelected={isSelected}
                exerciseList={exerciseProprioList}
                numberOfNeededExercises={1}
              />
            </ProgramExerciseProvider>
            <ProgramExerciseProvider>
              <ProgramExerciceTableForm
                handleClick={handleClick}
                isSelected={isSelected}
                exerciseList={exerciseStretchList}
                numberOfNeededExercises={3}
                last
              />
            </ProgramExerciseProvider>
          </Grid>
        </Paper>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
        >
          {selectedExercicesCount !== 9
            && (
              <Grid item>
                <Typography variant="body1">
                  Vous devez sélectionner 9 exercices pour pouvoir créer le programme
                </Typography>
              </Grid>
            )}
        </Grid>
      </Grid>
    </form>
  );
}

ProgramForm.propTypes = {
  program: PropTypes.object,
  pageTitle: PropTypes.string.isRequired,
};

ProgramForm.defaultProps = {
  program: null,
};

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { postAdvice, putAdvice } from '../../store/advice/action';
import { ADVICES } from '../../constants/routes';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import { themeValues, getAdviceThemeLabel } from '../../constants/adviceTheme';

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
});

export default function AdviceForm({
  advice,
}) {
  const { sending } = useSelector((state) => state.advice);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [theme, setTheme] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [themeError, setThemeError] = useState('');
  const { notifyApiError } = useContext(NotificationContext);
  const { token } = useUserAuth();
  useEffect(() => {
    if (advice !== undefined && advice !== null) {
      setId(advice.id);
      setDescription(advice.description);
      setTheme(advice.theme);
    }
  }, [advice]);

  const isEditing = id !== '';

  const checkValues = (value) => value !== theme.trim();

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let errorCount = 0;
    if (description.trim() === '') {
      setDescriptionError('Champ obligatoire');
      errorCount++;
    }
    if (theme.trim() === '' || themeValues.every(checkValues)) {
      setThemeError('Champ obligatoire');
      errorCount++;
    }
    if (errorCount === 0 && token !== null) {
      const data = {
        id,
        description,
        theme,
      };
      if (isEditing) {
        dispatch(putAdvice(token, id, data))
          .then(() => navigate(ADVICES))
          .catch((error) => notifyApiError(error));
      } else {
        dispatch(postAdvice(token, data))
          .then(() => navigate(ADVICES))
          .catch((error) => notifyApiError(error));
      }
    }
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            id="description"
            label="Description"
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError('');
            }}
            name="description"
            value={description}
            multiline
            fullWidth
            error={descriptionError !== ''}
            helperText={descriptionError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <FormControl variant="standard" error={themeError !== ''}>
            <InputLabel id="labelType" error={themeError !== ''}>
              Theme
            </InputLabel>
            <Select
              labelId="labelType"
              id="type"
              name="type"
              value={theme}
              onChange={(e) => {
                setTheme(e.target.value);
                setThemeError('');
              }}
              error={themeError !== ''}
            >
              {themeValues.map((value) => (
                <MenuItem value={value} key={value}>
                  {getAdviceThemeLabel(value)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={themeError !== ''}>
              {themeError}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={sending}
          >
            {isEditing
              ? 'Editer'
              : 'Valider'}
            {sending && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

AdviceForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  advice: PropTypes.object,
};

AdviceForm.defaultProps = {
  advice: null,
};

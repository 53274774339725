const CONSEILS_EXERCICES = 'CONSEILS EXERCICES';
const PATHOLOGIE = 'PATHOLOGIE';
const REGLES_HYGIENO_DIETETIQUES = 'REGLES HYGIENO DIETETIQUES';
const TRAITEMENT = 'TRAITEMENT';

const themeValues = [
  CONSEILS_EXERCICES,
  PATHOLOGIE,
  REGLES_HYGIENO_DIETETIQUES,
  TRAITEMENT,
];

const getAdviceThemeLabel = (theme) => {
  switch (theme) {
    case TRAITEMENT:
      return 'Traitement';
    case PATHOLOGIE:
      return 'Pathologie';
    case REGLES_HYGIENO_DIETETIQUES:
      return 'Règles hygiéno-diététiques';
    case CONSEILS_EXERCICES:
      return 'Conseils exercices';
    default:
      return theme;
  }
};
export { themeValues, getAdviceThemeLabel };

import React, {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import auth from '../firebase/config';

const userAuthContext = createContext({
  token: null,
  user: null,
  logIn: () => {
  },
  logOut: () => {
  },
  logInPending: false,
});

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(null);
  const [logInPending, setLogInPending] = useState(false);

  const logIn = (email, password) => {
    setLogInPending(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
        return userCredential.user.getIdToken(true);
      })
      .then(async (newToken) => {
        await setToken(newToken);
        return newToken;
      })
      .catch((error) => {
        setUser(null);
        setToken(null);
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      })
      .finally(() => {
        setLogInPending(false);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      if (token === null && !logInPending && currentuser !== null) {
        currentuser.getIdToken(true)
          .then(async (newToken) => {
            await setToken(newToken);
            return newToken;
          })
          .catch((error) => {
            setUser(null);
            setToken(null);
            // eslint-disable-next-line no-console
            console.error(error);
            throw error;
          })
          .finally(() => {
            setLogInPending(false);
          });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  function logOut() {
    setUser(null);
    setToken(null);
    return signOut(auth);
  }

  return (
    <userAuthContext.Provider
      value={{
        token,
        setToken,
        user,
        logIn,
        logOut,
        logInPending,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

UserAuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useUserAuth() {
  return useContext(userAuthContext);
}

import axios from 'axios';
import API_ROOT from '../constants/api';
import { getHeaders } from '../services/ApiServices';

const ROUTE = '/admin/questions';

export const apiGetQuestions = (token) => axios
  .get(`${API_ROOT}${ROUTE}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

export const apiPostQuestion = (token, data) => axios
  .post(`${API_ROOT}${ROUTE}`, data, {
    headers: {
      ...getHeaders(token),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  .then((response) => response.data);

export const apiPutQuestion = (token, id, data) => axios
  .post(`${API_ROOT}${ROUTE}/${id}`, data, {
    headers: {
      ...getHeaders(token),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  .then((response) => response.data);

export const apiDeleteQuestion = (token, id) => axios
  .delete(`${API_ROOT}${ROUTE}/${id}`, {
    headers: getHeaders(token),
  })
  .then((response) => response.data);

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import {
  CONGRATULATIONS_MESSAGE_ADD,
  CONGRATULATIONS_MESSAGE_EDIT,
} from '../../../constants/routes';
import ValidationDialog from '../../../components/ValidationDialog/ValidationDialog';
import PageCircularProgress from '../../../components/PageCircularProgress/PageCircularProgress';
import PageTitle from '../../../components/PageTitle/PageTitle';
import {
  deleteCongratulationsMessage,
  getCongratulationsMessages,
} from '../../../store/congratulationsMessage/action';
import { useUserAuth } from '../../../contexts/UserAuthContext.js';

export default function CongratulationsMessagesPage() {
  const {
    pending,
    content,
    error,
    sending,
  } = useSelector((state) => state.congratulationsMessage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [congratulationsMessageToDelete, setCongratulationsMessageToDelete] = useState(null);
  const { token } = useUserAuth();

  if (!content && !pending && !error && token !== null) {
    dispatch(getCongratulationsMessages(token));
  }

  const handleAdd = () => {
    navigate(CONGRATULATIONS_MESSAGE_ADD);
  };

  const handleEdit = (congratulationsMessagesPage) => {
    navigate(
      CONGRATULATIONS_MESSAGE_EDIT
        .replace(':congratulationsMessageId', congratulationsMessagesPage.id),
    );
  };

  const handleToggleDialog = (congratulationsMessage) => {
    setCongratulationsMessageToDelete(congratulationsMessage);
    setOpenDialog(!openDialog);
  };

  const handleDelete = (congratulationsMessage) => {
    dispatch(deleteCongratulationsMessage(token, congratulationsMessage))
      .then(handleToggleDialog);
  };

  return (
    <Container maxWidth={false}>
      <ValidationDialog
        handleClose={handleToggleDialog}
        openDialog={openDialog}
        handleAction={() => handleDelete(congratulationsMessageToDelete)}
        title="Êtes-vous sûr de vouloir supprimer cet encouragement ?"
        text="Vous êtes sur le point de supprimer un encouragement. Attention, cette action est irréversible."
        sending={sending}
      />
      <PageTitle
        title="Encouragements en fin de programme"
        onClick={handleAdd}
        actionTitle="Ajouter un encouragement"
      />
      {(
        pending || content === null
      ) && <PageCircularProgress />}
      {content !== null
        && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titre</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {content.map((congratulationsMessage) => (
                  <TableRow key={congratulationsMessage.id}>
                    <TableCell>
                      {congratulationsMessage.title}
                    </TableCell>
                    <TableCell>
                      {congratulationsMessage.message}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        id="edit"
                        aria-label="update"
                        onClick={() => handleEdit(congratulationsMessage)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        id="delete"
                        aria-label="update"
                        onClick={() => handleToggleDialog(congratulationsMessage)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </Container>
  );
}

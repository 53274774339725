import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ProgramForm from '../../components/forms/ProgramForm';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import { getPrograms } from '../../store/program/action';
import { getExercises } from '../../store/exercise/action';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import { artheDarkBlue } from '../../constants/colors';

export default function EditProgramPage() {
  const { programId } = useParams();
  const dispatch = useDispatch();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.program);
  const {
    exPending,
    exContent,
    exError,
  } = useSelector((state) => (
    {
      exPending: state.exercise.pending,
      exContent: state.exercise.content,
      exError: state.exercise.error,
    }
  ));
  const [program, setProgram] = useState(null);
  const { token } = useUserAuth();

  useEffect(() => {
    if (content !== null) {
      setProgram(content.find(
        (p) => p.id === programId,
      ));
    }
  }, [content]);

  if (content === null && !pending && !error && token !== null) {
    dispatch(getPrograms(token));
  }
  if (exContent === null && !exPending && !exError && token !== null) {
    dispatch(getExercises(token));
  }
  if (program !== null && !pending) {
    return (
      <Container maxWidth={false}>
        <Typography style={{ color: artheDarkBlue }}>
          Programmes &gt;
          <span style={{ fontWeight: 700 }}>
            {' '}
            {program.title}
          </span>
        </Typography>
        {(
          pending || content === null || exPending || exContent === null
        ) && <PageCircularProgress />}
        {content !== null && exContent !== null
          && (
            <ProgramForm
              program={program}
              pageTitle={program.title}
            />
          )}
      </Container>
    );
  }
}

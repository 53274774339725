import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const PageTitle = ({
  title,
  actionTitle,
  onClick,
  disabled,
  sending,
  icon,
}) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    spacing={2}
    style={{ width: '100%' }}
  >
    <Grid item>
      <Typography variant="h1">
        {title}
      </Typography>
    </Grid>
    {actionTitle && onClick && (
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={onClick}
          disabled={disabled}
          startIcon={icon
            ? <img src={icon} alt="icon on button" />
            : ''}
        >
          {actionTitle}
          {sending && <CircularProgress size={24} />}
        </Button>
      </Grid>
    )}
  </Grid>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  actionTitle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  sending: PropTypes.bool,
  icon: PropTypes.string,
};

PageTitle.defaultProps = {
  actionTitle: null,
  onClick: null,
  disabled: false,
  sending: false,
  icon: null,
};

export default PageTitle;

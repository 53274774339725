import {
  CONGRATULATIONS_MESSAGE_REMOVED,
  CONGRATULATIONS_MESSAGE_FAILED,
  CONGRATULATIONS_MESSAGE_PENDING,
  CONGRATULATIONS_MESSAGES_DOWNLOADED,
  CONGRATULATIONS_MESSAGE_SENDING,
  CONGRATULATIONS_MESSAGE_DOWNLOADED,
} from './types';
import {
  apiDeleteCongratulationsMessage,
  apiGetCongratulationsMessages,
  apiPostCongratulationsMessage, apiPutCongratulationsMessage,
} from '../../api/CongratulationsMessageApi';

export const congratulationsMessagesDownloadedAction = (congratulationsMessage) => (
  {
    type: CONGRATULATIONS_MESSAGES_DOWNLOADED,
    payload: congratulationsMessage,
  }
);
export const congratulationsMessageDownloadedAction = (congratulationsMessage) => (
  {
    type: CONGRATULATIONS_MESSAGE_DOWNLOADED,
    payload: congratulationsMessage,
  }
);
export const congratulationsMessagePendingAction = () => (
  {
    type: CONGRATULATIONS_MESSAGE_PENDING,
  }
);
export const congratulationsMessageSendingAction = () => (
  {
    type: CONGRATULATIONS_MESSAGE_SENDING,
  }
);
export const congratulationsMessageRemovedAction = (congratulationsMessage) => (
  {
    type: CONGRATULATIONS_MESSAGE_REMOVED,
    payload: congratulationsMessage,
  }
);
export const congratulationsMessageFailedAction = (error) => (
  {
    type: CONGRATULATIONS_MESSAGE_FAILED,
    payload: error,
  }
);

export function getCongratulationsMessages(token) {
  return (dispatch) => {
    dispatch(congratulationsMessagePendingAction());
    return apiGetCongratulationsMessages(token)
      .then((result) => {
        dispatch(congratulationsMessagesDownloadedAction(result));
        return result;
      })
      .catch((error) => {
        dispatch(congratulationsMessageFailedAction(error));
        throw error;
      });
  };
}

export function postCongratulationsMessage(token, data) {
  return (dispatch) => {
    dispatch(congratulationsMessageSendingAction());
    return apiPostCongratulationsMessage(token, data)
      .then((result) => {
        dispatch(congratulationsMessageDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(congratulationsMessageFailedAction(error));
        throw error;
      });
  };
}

export function putCongratulationsMessage(token, id, data) {
  return (dispatch) => {
    dispatch(congratulationsMessageSendingAction());
    return apiPutCongratulationsMessage(token, id, data)
      .then((result) => {
        dispatch(congratulationsMessageDownloadedAction(result));
        return result.id;
      })
      .catch((error) => {
        dispatch(congratulationsMessageFailedAction(error));
        throw error;
      });
  };
}

export function deleteCongratulationsMessage(token, congratulationsMessage) {
  return (dispatch) => {
    dispatch(congratulationsMessageSendingAction());
    return apiDeleteCongratulationsMessage(token, congratulationsMessage.id)
      .then(() => {
        dispatch(congratulationsMessageRemovedAction(congratulationsMessage));
        return congratulationsMessage;
      })
      .catch((error) => {
        dispatch(congratulationsMessageFailedAction(error));
        throw error;
      });
  };
}

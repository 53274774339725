import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import { DialogContent, DialogContentText, ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {
  artheNuance050, artheBlue, artheDarkBlue, artheRed, artheBlueHover,
} from '../../constants/colors';
import CheckMarkIcon from '../../components/icon/Checkmark';
import SaveButton from '../../components/button/SaveButton';
import CancelButton from '../../components/button/CancelButton';
import {
  postNecessaryEquipment,
} from '../../store/necessaryEquipment/action';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import useStyles from './NecessaryEquipmentDialog.style';

const NecessaryEquipmentDialog = ({
  content,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { notifyApiError } = useContext(NotificationContext);
  const { token } = useUserAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const handleChange = (id, value) => {
    const index = data.findIndex((d) => d.id === id);
    if (index >= 0) {
      const newData = [...data];
      newData[index].name = value;
      if (newData[index].status === 'NEW') {
        newData[index].status = 'NEW';
        setData(newData);
      } else {
        newData[index].status = 'EDIT';
        setData(newData);
      }
    }
  };

  const addNewEquipment = () => {
    setData([
      ...data, {
        id: (
          Math.random() * 1000
        ).toString(),
        name: '',
        status: 'NEW',
      },
    ]);
  };

  const changeStatusToDelete = (id) => {
    const index = data.findIndex((d) => d.id === id);
    if (index >= 0) {
      const newData = [...data];
      if (newData[index].status === 'NEW') {
        setData(data.filter((d) => d.status === 'OLD' || d.status === 'EDIT'));
      } else {
        newData[index].status = 'DELETE';
        setData(newData);
      }
    }
  };

  const handleSubmit = () => {
    dispatch(postNecessaryEquipment(token, data))
      .then(() => {
        setOpen(false);
        onClose();
      })
      .catch((error) => notifyApiError(error));
  };

  useEffect(() => {
    setData(content.map((c) => (
      {
        id: c.id,
        name: c.name,
        status: 'OLD',
        is_mandatory: c.is_mandatory,
        number_exercises: c.number_exercises,
      }
    )));
  }, [content]);
  return (
    <>
      <List className={classes.root}>
        {data.map((d) => (
          <ListItemButton
            className={classes.container}
            key={d.id}
            disabled={d.is_mandatory === true || d.status === 'DELETE'}
          >
            <ListItemIcon>
              <CheckMarkIcon />
            </ListItemIcon>
            <TextField
              variant="standard"
              size="small"
              InputProps={{
                disableUnderline: true,
              }}
              disabled={d.is_mandatory === true || d.status === 'DELETE'}
              value={d.name}
              onChange={(e) => handleChange(d.id, e.target.value)}
              className={classes.input}
              placeholder={d.status === 'NEW'
                ? 'Matériel à définir'
                : undefined}
            />
            {
              !d.is_mandatory
                ? (
                  <Grid item>
                    <IconButton
                      id="delete"
                      aria-label="delete"
                      color="default"
                      onClick={() => changeStatusToDelete(d.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )
                : ''
            }
          </ListItemButton>
        ))}

      </List>
      <Button
        style={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '0 30px',
          marginTop: '0',
          width: '234px',
        }}
        type="button"
        onClick={() => addNewEquipment()}
      >
        <Typography
          style={{
            color: artheBlueHover,
            textTransform: 'uppercase',
          }}
        >
          <AddIcon color="inherit" style={{ verticalAlign: 'bottom' }} />
          Ajouter un matériel
        </Typography>

      </Button>
      <Grid
        container
        style={{
          justifyContent: 'flex-end',
          gap: '8px',
          flexWrap: 'nowrap',
          padding: '0 30px 35px 30px',
        }}
      >
        <CancelButton onClick={onClose} />
        <SaveButton
          onClick={data.filter((d) => d.status === 'DELETE').length > 0
            ? handleClickOpen
            : handleSubmit}
        />
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle
            variant="h6"
            style={{ color: artheDarkBlue }}
          >
            Modification du matériel global
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vous êtes sur le point de supprimer définitivement:
              {data.filter((d) => d.status === 'DELETE').map((d) => (
                <DialogContentText>
                  {' - '}
                  {d.name}
                  {' présent dans '}
                  {d.number_exercises}
                  {' exercices. '}
                </DialogContentText>
              ))}
              Êtes-vous certain de vouloir sauvegarder ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              type="button"
              style={{
                minWidth: 'unset',
                color: artheBlue,
                borderRadius: '13px',
                boxShadow: '0px 5px 8px 0px rgba(40, 119, 135, 0.08)',
                '&:hover': {
                  color: artheBlueHover,
                },
              }}
            >
              Non, annuler
            </Button>
            <Button
              style={{
                minWidth: 'unset',
                color: artheNuance050,
                backgroundColor: artheRed,
              }}
              color="primary"
              onClick={handleSubmit}
              type="button"
            >
              Oui, sauvegarder
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default NecessaryEquipmentDialog;

NecessaryEquipmentDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

NecessaryEquipmentDialog.defaultProps = {
  content: null,
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ProgramForm from '../../components/forms/ProgramForm';
import { getExercises } from '../../store/exercise/action';
import { getPrograms } from '../../store/program/action';
import PageCircularProgress from '../../components/PageCircularProgress/PageCircularProgress';
import { useUserAuth } from '../../contexts/UserAuthContext.js';
import { artheDarkBlue } from '../../constants/colors';

export default function AddProgramPage() {
  const dispatch = useDispatch();
  const {
    pending,
    content,
    error,
  } = useSelector((state) => state.program);
  const {
    exPending,
    exContent,
    exError,
  } = useSelector((state) => (
    {
      exPending: state.exercise.pending,
      exContent: state.exercise.content,
      exError: state.exercise.error,
    }
  ));
  const { token } = useUserAuth();

  if (content === null && !pending && !error && token !== null) {
    dispatch(getPrograms(token));
  }
  if (exContent === null && !exPending && !exError && token !== null) {
    dispatch(getExercises(token));
  }

  return (
    <Container maxWidth={false}>
      <Typography style={{ color: artheDarkBlue }}>
        Programmes &gt;
        <span style={{ fontWeight: 700 }}> Ajouter un programme</span>
      </Typography>
      {(
        pending || content === null || exPending || exContent === null
      ) && <PageCircularProgress />}
      {content !== null && exContent !== null
        && (
          <ProgramForm pageTitle="Ajouter un programme" />
        )}
    </Container>
  );
}

import axios from 'axios';
import API_ROOT from '../constants/api';
import { getHeaders } from '../services/ApiServices';

const ROUTE = '/admin/csv';

export const apiGetCsv = (token) => axios
  .get(`${API_ROOT}${ROUTE}`, {
    headers: getHeaders(token),
    responseType: 'blob',
  })
  .then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'stats.csv');
    document.body.appendChild(link);
    link.click();
  });

export default {
  apiGetCsv,
};

import {
  ADVICES_DOWNLOADED,
  ADVICE_SENDING,
  ADVICE_RESET,
  ADVICE_REMOVED,
  ADVICE_PENDING,
  ADVICE_FAILED,
  ADVICE_DOWNLOADED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: null,
  sending: null,
};

// eslint-disable-next-line func-names
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADVICE_RESET:
      return INITIAL_STATE;
    case ADVICE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case ADVICES_DOWNLOADED:
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case ADVICE_PENDING:
      return {
        ...state,
        error: null,
        pending: true,
      };
    case ADVICE_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case ADVICE_DOWNLOADED: {
      const adviceDownloaded = action.payload;
      let adviceList = [];
      let adviceIndex = -1;
      if (state.content) {
        adviceList = state.content.slice();
        adviceIndex = adviceList.findIndex((advice) => advice.id === adviceDownloaded.id);
      }
      if (adviceIndex === -1) {
        adviceList.push(action.payload);
      } else {
        adviceList[adviceIndex] = action.payload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: adviceList,
      };
    }
    case ADVICE_REMOVED: {
      const advice = action.payload;
      let adviceList = state.content;
      if (adviceList !== null) {
        // eslint-disable-next-line func-names
        const isAdvice = (adviceId) => function (element) {
          return element.id !== adviceId;
        };
        adviceList = state.content.filter(isAdvice(advice.id));
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: adviceList,
      };
    }
    default:
      break;
  }
  return state;
}

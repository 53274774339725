import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { artheBlueHover, artheBlue } from '../../constants/colors';

const useStyles = makeStyles(() => (
  {
    container: {
      minWidth: 'unset',
      color: artheBlue,
      borderRadius: '13px',
      boxShadow: '0px 5px 8px 0px rgba(40, 119, 135, 0.08)',
      '&:hover': {
        color: artheBlueHover,
      },
    },
  }
));

const CancelButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      type="button"
      className={classes.container}
      onClick={onClick}
    >
      Annuler
    </Button>
  );
};

export default CancelButton;

CancelButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
};

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { EXERCISE_ENCOURAGEMENT_MESSAGE } from '../../constants/routes';
import {
  postExerciseEncouragementMessage,
  putExerciseEncouragementMessage,
} from '../../store/exerciseEncouragementMessage/action';
import { CIRCULAR_PROGRESS } from '../../constants/stylesDefault';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useUserAuth } from '../../contexts/UserAuthContext.js';

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  theme: {
    marginLeft: 30,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  gridContainerLimit: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
  },
  loading: CIRCULAR_PROGRESS,
});

export default function ExerciseEncouragementMessageForm({ exerciseEncouragementMessage }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useUserAuth();

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const { notifyApiError } = useContext(NotificationContext);

  const { sending } = useSelector(
    (state) => state.exerciseEncouragementMessage,
  );

  useEffect(() => {
    if (exerciseEncouragementMessage !== undefined && exerciseEncouragementMessage !== null) {
      setId(exerciseEncouragementMessage.id);
      setTitle(exerciseEncouragementMessage.title);
      setMessage(exerciseEncouragementMessage.message);
    }
  }, [exerciseEncouragementMessage]);

  const isEditing = id !== '';

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let errorCount = 0;
    if (title.trim() === '') {
      setTitleError('Champ obligatoire');
      errorCount++;
    }
    if (message.trim() === '') {
      setMessageError('Champ obligatoire');
      errorCount++;
    }
    if (errorCount === 0) {
      const data = {
        id,
        title,
        message,
      };
      if (isEditing) {
        dispatch(putExerciseEncouragementMessage(token, id, data))
          .then(() => navigate(EXERCISE_ENCOURAGEMENT_MESSAGE))
          .catch((error) => notifyApiError(error));
      } else {
        dispatch(postExerciseEncouragementMessage(token, data))
          .then(() => navigate(EXERCISE_ENCOURAGEMENT_MESSAGE))
          .catch((error) => notifyApiError(error));
      }
    }
  };

  return (
    <form onSubmit={onSubmit} className={classes.form}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            id="title"
            label="title"
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            value={title}
            multiline
            fullWidth
            error={titleError !== ''}
            helperText={titleError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            id="message"
            label="Message"
            onChange={(e) => {
              setMessage(e.target.value);
              setMessageError('');
            }}
            value={message}
            multiline
            fullWidth
            error={messageError !== ''}
            helperText={messageError}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={sending}
          >
            {isEditing
              ? 'Editer'
              : 'Valider'}
            {sending && <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

ExerciseEncouragementMessageForm.propTypes = {
  exerciseEncouragementMessage: PropTypes.object,
};

ExerciseEncouragementMessageForm.defaultProps = {
  exerciseEncouragementMessage: null,
};
